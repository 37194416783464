<script setup lang="ts">
import { computed, Ref, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  format,
  formatDuration,
  intervalToDuration,
  milliseconds,
} from 'date-fns'
import { t } from 'i18next'

// repo
import { useCurrencyDisplay } from '@/global/index'
import { useAppStore } from '@/stores/AppStore'

// components
import Header from '@components/Header.vue'
import Footer from '@components/Footer.vue'

// type
import { Service, Medicine, CourseLineSellFormated } from '@/type/Course'

interface ServiceMedicineList extends Service, Medicine {
  type: 'service' | 'medicine'
}

const CART_LIMIT = 10

const appStore = useAppStore()
const router = useRouter()
const route = useRoute()
const course: Ref<CourseLineSellFormated | null> = ref(null)
const cost_toggle: Ref<boolean> = ref(false)
const collapse_active = ref(['1'])

const add_snackbar_toggle = ref(false)
const openAddSnackBar = () => {
  add_snackbar_toggle.value = true
}
const closeAddSnackBar = () => {
  add_snackbar_toggle.value = false
}

const cartFullSnackBar = ref(false)
const openCartFullSnackBar = () => {
  cartFullSnackBar.value = true
}
const closeCartFullSnackBar = () => {
  cartFullSnackBar.value = false
}
const cartOverQuotaSnackBar = ref(false)
const openCartOverQuotaSnackBar = () => {
  cartOverQuotaSnackBar.value = true
}
const closeCartOverQuotaSnackBar = () => {
  cartOverQuotaSnackBar.value = false
}

const course_buy_left = computed(() => {
  if (!course.value) return ''
  return course.value.buy_limit - course.value.buy_count
})

const course_type = computed(() => {
  if (!course.value) return ''

  switch (course.value.type) {
    case '01':
      return t('course.normal_course')
    case '03':
      return t('course.buffet')
    case '04':
      return t('course.free')
    case '05':
      return t('course.estimate')
    default:
      return ''
  }
})

const durationToDistance = (day: number) => {
  const __duration = intervalToDuration({
    start: 0,
    end: milliseconds({ days: day }),
  })
  const formatted = formatDuration(__duration, {
    format: ['years', 'days'],
    delimiter: ' ',
  })

  return formatted
    .replace('years', t('year'))
    .replace('year', t('year'))
    .replace('days', t('date'))
    .replace('day', t('date'))
}

const course_expire_date = computed(() => {
  if (!course.value) return ''

  if (!course.value.patientcourse_end_date && course.value.duration === 0)
    return t('course.no_expire')

  if (course.value.patientcourse_end_date)
    return format(new Date(course.value.patientcourse_end_date), 'dd MMM yyyy')

  if (course.value?.expire_after_use_flag) {
    return `${durationToDistance(course.value.duration)} ${t(
      'course.duration_no_date',
    )}`
  }

  return `${durationToDistance(course.value.duration)} ${t(
    'course.duration_after_buy_no_date',
  )}`
})

const service_and_medicine_list = computed(() => {
  if (!course.value) return []

  let list: ServiceMedicineList[] = []
  // add service
  course.value.services.forEach(item => {
    if (item.show_receipt_flag) {
      list.push({
        ...item,
        type: 'service',
        size: 0,
        unit: '',
      })
    }
  })

  // add medicine
  course.value.medicines.forEach(item => {
    if (item.show_receipt_flag) {
      list.push({
        ...item,
        type: 'medicine',
        description: null,
      })
    }
  })

  return list
})

const checkCartBuyQuotas = (course: CourseLineSellFormated) => {
  // buy_limit = 0 (ไม่จำกัดครั้งซื้อ) @fook-piyapit
  if (course.buy_limit === 0) return true

  const course_cart_count = appStore.cart.filter(
    cart => cart.course_uuid === course.uuid,
  ).length

  if (course_cart_count + course.buy_count >= course.buy_limit) {
    return false
  }

  return true
}

const onBack = () => {
  router.back()
}

const addToCart = (course: CourseLineSellFormated) => {
  if (!appStore.clinic) {
    throw new Error('no clinic')
  }

  if (!course.liff_data.cost_on_LINE) {
    throw new Error('no course price')
  }

  if (appStore.cart.length === CART_LIMIT) {
    openCartFullSnackBar()
    setTimeout(() => closeCartFullSnackBar(), 3000)
    return
  }

  if (!checkCartBuyQuotas(course)) {
    openCartOverQuotaSnackBar()
    setTimeout(() => closeCartOverQuotaSnackBar(), 3000)
    return
  }

  const cart = {
    image_url: course.liff_data.image_url,
    name: course.name,
    course_uuid: course.uuid,
    price: course.liff_data.cost_on_LINE,
    deposit_cost: course.liff_data.pledge_cost,
    detail: course,
    fee: 0, // ไปคำนวณที่หน้า checkout
    vat: 0, // ไปคำนวณที่หน้า checkout
  }
  openAddSnackBar()

  if (cost_toggle.value) {
    // case deposit

    appStore.addCart({
      ...cart,
      is_deposit: true,
    })
  } else {
    // case full price

    appStore.addCart({
      ...cart,
      is_deposit: false,
    })
  }
  setTimeout(closeAddSnackBar, 2000)
}

const checkout = () => {
  router.replace({ name: 'CourseCheckout' })
}

const addAndCheckOut = (course: CourseLineSellFormated) => {
  addToCart(course)
  checkout()
}

onMounted(async () => {
  const course_uuid = route.params.course_uuid as string
  const filter_course =
    appStore.sell_course?.find(item => item.uuid + '' == course_uuid) || null
  course.value = filter_course
  cost_toggle.value = course.value?.liff_data.is_pledge || false
})
</script>

<template>
  <div class="body">
    <div class="image-box">
      <van-image class="image" fit="cover" :src="course?.liff_data.image_url" />
    </div>

    <div class="text-center content-cell">
      <span v-html="course?.liff_data.title"></span>
      <span v-html="course?.liff_data.note"></span>
    </div>

    <van-cell-group inset class="content-cell">
      <van-cell
        :title="`${$t('course.expire_date')} / ${$t('course.time_total_use2')}`"
        :value="course_expire_date"
      />
      <van-cell :title="$t('course.type')" :value="course_type" />
      <van-cell
        v-if="course?.buy_limit && course.buy_limit > 0"
        :title="$t('course.buy_course.buy_limit')"
        title-style="font-weight: bold; text-decoration: underline;"
        :value="course_buy_left"
      />
    </van-cell-group>

    <van-cell-group inset class="content-cell">
      <van-cell
        v-if="course?.liff_data.is_pledge"
        center
        :title="$t('course.price')"
      >
        <template #right-icon>
          <van-switch v-model="cost_toggle" />
        </template>
      </van-cell>
      <van-cell
        v-if="!cost_toggle"
        title-style="color: green"
        :title="$t('course.buy_course.full_price')"
        :value="useCurrencyDisplay(course?.liff_data.cost_on_LINE || 0)"
      />
      <van-cell
        v-if="cost_toggle"
        title-style="color: orange"
        :title="$t('course.buy_course.deposit')"
        :value="useCurrencyDisplay(course?.liff_data.pledge_cost || 0)"
      />
    </van-cell-group>

    <van-cell-group
      v-if="course?.liff_data.show_course_item"
      inset
      class="content-cell"
    >
      <van-collapse
        v-if="service_and_medicine_list.length > 0"
        v-model="collapse_active"
      >
        <van-collapse-item :title="$t('course.can_use_list')" name="1">
          <div v-for="item in service_and_medicine_list">
            <van-card
              :currency="course?.liff_data.show_item_price ? '฿ ' : undefined"
              :title="item.name"
              :price="
                course?.liff_data.show_item_price ? item.price : undefined
              "
            >
              <template #tags>
                <van-tag
                  style="margin-left: 5px"
                  v-show="item.type === 'medicine'"
                  plain
                  color="#1989FA"
                  >{{ $t('course.medicine') }}</van-tag
                >
                <van-tag
                  style="margin-left: 5px"
                  v-show="item.type === 'service'"
                  plain
                  color="#EE0A24"
                  >{{ $t('course.service') }}</van-tag
                >
              </template>
              <template #num>
                <span
                  v-if="
                    item.show_amount_receipt_flag &&
                    course?.liff_data.show_item_amount
                  "
                  style="color: black; font-weight: bold"
                  >x{{ item.amount }}</span
                >
                <span v-else></span>
              </template>
            </van-card>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>
  </div>

  <Header :onBack="onBack">
    <template v-slot:right>
      <van-col span="13">
        <van-row align="center" class="text-center">
          <van-col span="17"
            ><span class="text-bold">{{
              appStore.patient?.fname
            }}</span></van-col
          >
          <van-col style="margin-right: 10px" span="4">
            <van-badge
              :content="
                appStore.cart.length > 0 ? appStore.cart.length : undefined
              "
            >
              <van-icon
                name="shopping-cart-o"
                size="25"
                @click="checkout"
                color="black"
              />
            </van-badge>
          </van-col>
        </van-row>
      </van-col>
    </template>
  </Header>

  <Footer
    :styles="{
      'padding-top': '10px',
      'padding-bottom': '10px',
      'background-color': 'white',
    }"
  >
    <van-row justify="center" align="center">
      <van-col span="7">
        <div
          @click="addToCart(course!)"
          style="
            height: 40px;
            width: 40px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>cart-plus</title>
            <path
              d="M11 9H13V6H16V4H13V1H11V4H8V6H11M7 18C5.9 18 5 18.9 5 20S5.9 22 7 22 9 21.1 9 20 8.1 18 7 18M17 18C15.9 18 15 18.9 15 20S15.9 22 17 22 19 21.1 19 20 18.1 18 17 18M7.2 14.8V14.7L8.1 13H15.5C16.2 13 16.9 12.6 17.2 12L21.1 5L19.4 4L15.5 11H8.5L4.3 2H1V4H3L6.6 11.6L5.2 14C5.1 14.3 5 14.6 5 15C5 16.1 5.9 17 7 17H19V15H7.4C7.3 15 7.2 14.9 7.2 14.8Z"
            />
          </svg>
        </div>
      </van-col>
      <van-col span="14">
        <van-button @click="addAndCheckOut(course!)" block type="primary">{{
          $t('course.buy_course.buy')
        }}</van-button>
      </van-col>
    </van-row>
  </Footer>

  <van-notify v-model:show="add_snackbar_toggle" type="success">
    <span>{{ $t('course.buy_course.add_to_cart') }}</span>
  </van-notify>

  <van-notify v-model:show="cartFullSnackBar" type="danger">
    <span>{{ $t('course.buy_course.cart_full', { num: CART_LIMIT }) }}</span>
  </van-notify>

  <van-notify v-model:show="cartOverQuotaSnackBar" type="danger">
    <span>{{
      $t('course.buy_course.cart_over_buy_quota', { limit: course?.buy_limit })
    }}</span>
  </van-notify>
</template>

<style scoped>
.body {
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  margin-bottom: 90px;
}
.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.active {
  color: green;
}

.inactive {
  color: #555555;
}

.image-box {
  display: flex;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: 80%;
  max-height: 500px;
}

.content-cell {
  margin: 10px;
}
</style>
