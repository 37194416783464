import axios from 'axios'
import { useAppStore } from '@/stores/AppStore'
import { CreateReviewDTO } from '@/type/Feedback'

export function useFeedbackRepository(clinic_uuid: string) {
  const appStore = useAppStore()

  function fetchOPDReview(opd_uuid: string) {
    return axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/feedback/?opd_uuid=${opd_uuid}&clinic_uuid=${clinic_uuid}&line_user_id=${appStore.userProfile.userId}`,
    )
  }

  function createOPDReview(opd_uuid: string, review_data: CreateReviewDTO[]) {
    return axios.post(
      `${appStore.url}/api/v1/crm/line-app/p/feedback/?opd_uuid=${opd_uuid}&clinic_uuid=${clinic_uuid}&line_user_id=${appStore.userProfile.userId}`,
      review_data,
    )
  }

  return {
    fetchOPDReview,
    createOPDReview,
  }
}
