import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from '@/router'
import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
// import vSelect from 'vue-select'
import { initSentry } from './sentry'

const pinia = createPinia()
const app = createApp(App)
app.use(I18NextVue, { i18next })
app.use(pinia)
export const Sentry = initSentry(app, router)
app.use(router)
// app.component('v-select', vSelect)
app.mount('#app')
