<script setup lang="ts">
import { RenderPatientcourseitemSet } from '@/type/Course'
import { ref } from 'vue'
import { format } from 'date-fns'

defineProps<{
  course_type: '01' | '02' | '03' | '04' | '05'
  itemList: RenderPatientcourseitemSet[]
  collapse_title: string
  only_cell: boolean
  showPrice: boolean
  showUsedDate: boolean
  showMedicineTag: boolean
  showServiceTag: boolean
  showUsedTag: boolean
}>()
const list_toggle = ref(1)

// $t('course.can_use_list')
</script>

<template>
  <van-cell v-if="only_cell" :title="collapse_title"></van-cell>

  <van-collapse v-else="" v-model="list_toggle" accordion>
    <van-collapse-item :title="collapse_title">
      <div v-for="(item, index) in itemList">
        <van-card
          style="margin-bottom: 10px"
          v-if="item.show_receipt_flag"
          :key="index"
          :num="item.show_amount_receipt_flag ? item.counter : undefined"
          :title="item.productset_name"
          :currency="showPrice ? '฿ ' : undefined"
          :price="showPrice ? item.price : undefined"
        >
          <template #tags>
            <van-tag v-if="showUsedTag && item.used" plain type="danger">{{
              $t('already_use')
            }}</van-tag>
            <van-tag v-if="showUsedTag && !item.used" plain type="success">{{
              $t('ready_to_use')
            }}</van-tag>
            <van-tag
              style="margin-left: 5px"
              v-show="showMedicineTag && item.medicine"
              plain
              color="#1989FA"
              >{{ $t('course.medicine') }}</van-tag
            >
            <van-tag
              style="margin-left: 5px"
              v-show="showServiceTag && item.service"
              plain
              color="#EE0A24"
              >{{ $t('course.service') }}</van-tag
            >
          </template>

          <!-- date -->
          <template v-if="showUsedDate && course_type === '05'" #footer>
            <div
              v-if="item.used && item.used_date"
              style="color: #969799; text-align: right"
            >
              {{ format(new Date(item.used_date), 'dd/MM/yyyy') }}
            </div>
          </template>
          <template v-else #bottom>
            <div
              v-if="showUsedDate && item.used && item.used_date"
              style="color: #969799"
            >
              {{ format(new Date(item.used_date), 'dd/MM/yyyy') }}
            </div>
          </template>
        </van-card>
      </div>
    </van-collapse-item>
  </van-collapse>
</template>

<style scoped></style>
