export function getParamsFromLiffUrl(url: string): URLSearchParams {
  const pathString = decodeURIComponent(decodeURIComponent(url)).replace(
    '?liff.state=',
    '',
  )
  const indexOfQueryString = pathString.lastIndexOf('?')
  const queryString = pathString.substring(indexOfQueryString)
  const params = new URLSearchParams(queryString)
  return params
}

export const validateRequiredParams = (params: URLSearchParams): void => {
  const requiredParams = ['clinicType', 'c', 'liffId']
  requiredParams.forEach(param => {
    if (!params.has(param)) {
      throw new Error(`Missing ${param}`)
    }
  })
}

export function setUpCrmUrl(subdomain: string): string {
  if (subdomain.includes('staging')) {
    return 'https://line-crm-staging.jeracloud.com'
  } else if (subdomain.includes('uat')) {
    return 'https://line-crm-uat.jeracloud.com'
  } else if (!import.meta.env.PROD) {
    return import.meta.env.VITE_LINE_CRM_URL
  } else {
    return 'https://line-crm.jeracloud.com'
  }
}

export function getAPIUrlFromClinicType(clinicType: string): string {
  if (!clinicType) {
    throw new Error('Clinic type not specified')
  }
  const JERA_CONFIG_SUBDOMAIN: Record<string, string> = {
    C1: 'https://api.jeracloud.com',
    C2: 'https://api2.jeracloud.com',
    A1: 'https://abc.jeracloud.com',
    D1: 'https://api.jeradent.com',
    U1: 'https://uat.jeracloud.com',
    S1: 'https://staging.jeracloud.com',
    X1: import.meta.env.VITE_JERA_URL,
  }
  return JERA_CONFIG_SUBDOMAIN[clinicType]
}

// Get subdomain from window.location.host without port
// Example https://uat.jeracloud.com:3000 => uat
// Example https://jeracloud.com => jeracloud
// Example http://localhost:3000 => localhost
// Example http://localhost => localhost
// Example https://uat.jeracloud.com => uat
export function getSubdomainFromHost() {
  const host = window.location.host
  const hostWithoutPort = host.split(':')[0]
  const subdomain = hostWithoutPort.split('.')[0]
  return subdomain
}

function isSupportLS() {
  try {
    localStorage.setItem('_test-key', 'hi')
    localStorage.getItem('_test-key')
    localStorage.removeItem('_test-key')
    return true
  } catch (e) {
    return false
  }
}

class Memory {
  cache: any
  constructor() {
    this.cache = {}
  }
  setItem(cacheKey: string, data: any) {
    this.cache[cacheKey] = data
  }
  getItem(cacheKey: string) {
    return this.cache[cacheKey]
  }
  removeItem(cacheKey: string) {
    this.cache[cacheKey] = undefined
  }
}
// if not support localStorage, fallback to memory
export const storage = isSupportLS() ? window.localStorage : new Memory()

export function stringToUUID(input: string): string {
  const uuidRx = new RegExp(
    /([a-zA-Z0-9]{8})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{5})([a-zA-Z0-9]{11})/,
  )
  const uuid = input.replace(uuidRx, '$1-$2-$3-$4-$5')
  return uuid
}
