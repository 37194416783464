<script lang="ts">
export default {
  inheritAttrs: false,
  customOptions: {},
}
</script>

<script lang="ts" setup>
const props = defineProps(['onBack'])
</script>

<template>
  <slot>
    <van-row align="center" justify="space-around" class="header">
      <slot name="left">
        <van-col span="11">
          <slot name="left-icon">
            <van-icon color="black" style="margin-left: 10px;" name="arrow-left" size="25" @click="props.onBack()" />
          </slot>
        </van-col>
      </slot>

      <slot name="right">
        <van-col style="margin-right: 10px;" span="11"></van-col>
      </slot>
    </van-row>
  </slot>
</template>

<style scoped>
.fake-header {
  height: 50px;
  padding: 15px;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #dddddd;
  justify-content: space-between;
}
</style>
