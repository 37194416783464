import { createRouter, createWebHistory } from 'vue-router'
import MemberDetail from '@/views/MemberDetail.vue'
import BFAlbum from '@/views/BFAlbum.vue'
import VoucherCondition from '@/views/VoucherCondition.vue'
import ChainPromotion from '@/views/ChainPromotion.vue'
import CourseDetail from '@/views/CourseDetail.vue'
import Redirect from '@/views/Redirect.vue'
import PatientCreateVue from '@/views/PatientCreate.vue'
import OPDFeedback from '@/views/OPDFeedback.vue'

// buy course
import CourseList from '@/views/buy_course/CourseList.vue'
import CourseSellDetail from '@/views/buy_course/CourseSellDetail.vue'
import CourseSummary from '@/views/buy_course/CourseSummary.vue'
import CourseCheckout from '@/views/buy_course/CourseCheckout.vue'

const routes = [
  // ===================
  // LINE CRM PAGES
  // ===================
  {
    path: '/auth/account-link',
    name: 'AccountLink',
    component: () => import('@/views/AccountLink.vue'),
    meta: {
      title: 'Account Link',
    },
  },
  {
    path: '/appointment',
    name: 'Appointment',
    component: () => import('@/views/Appointment.vue'),
    meta: {
      title: 'Appointment',
    },
  },
  {
    path: '/bf_showcase',
    name: 'BF_Album',
    component: BFAlbum,
    alias: '/bf_showcase',
  },
  {
    path: '/chain_promotion',
    name: 'ChainPromotion',
    component: ChainPromotion,
  },
  {
    path: '/course_detail',
    name: 'CourseDetail',
    component: CourseDetail,
  },
  // {
  //   path: '/feedback/:feedbackUUID',
  //   name: 'Feedback',
  //   props: true,
  //   component: () => import('@/views/Feedback.vue'),
  //   meta: {
  //     title: 'Feedback',
  //   },
  // },
  {
    path: '/feedback/',
    name: 'Feedback',
    component: OPDFeedback,
  },
  {
    path: '/member_detail',
    name: 'MemberDetail',
    component: MemberDetail,
    alias: '/member_detail',
  },
  {
    path: '/patient_create',
    name: 'PatientCreate',
    component: PatientCreateVue,
  },
  {
    path: '/voucher_condition',
    name: 'voucher_condition',
    component: VoucherCondition,
  },
  // ===================
  // BUY COURSE FEATURES
  // ===================
  { path: '/course_lists/', name: 'CourseList', component: CourseList },
  {
    path: '/course_list/:course_uuid',
    name: 'CourseSellDetail',
    component: CourseSellDetail,
  },
  {
    path: '/course_checkout/',
    name: 'CourseCheckout',
    component: CourseCheckout,
  },
  {
    path: '/course_summary/:status',
    name: 'CourseSummary',
    component: CourseSummary,
  },
  // ===================
  // DEFUALT ROUTES
  // ===================
  { path: '/redirect/:re/', name: 'redirect', component: Redirect },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/NotFound.vue'),
    alias: '/home',
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    return savedPosition || { top: 0 }
  },
})

router.beforeEach((to, _from) => {
  document.title = (to.meta?.title as string) ?? 'JERA LINE CRM'
})

export default router
