import { ref, computed, Ref, reactive } from 'vue'
import { defineStore } from 'pinia'
import { LineLiffProfile } from '@/type/Line'
import {
  CourseCart,
  CourseLineSellFormated,
  CoursePayment,
  CoursePaymentPayload,
} from '@/type/Course'
import { ClinicMini, PatientMini } from '@/type/Clinic'
import i18next from 'i18next'
import { stringToUUID } from '@/utils'

export const useAppStore = defineStore('AppStore', () => {
  const url = ref('')
  const crmUrl = ref('')
  const clinicUUID = ref('')
  const language = ref('')
  const patient: Ref<PatientMini | null> = ref(null)
  const clinic: Ref<ClinicMini | null> = ref(null)

  let userProfile: LineLiffProfile = reactive({
    displayName: '',
    pictureUrl: '',
    statusMessage: '',
    userId: '',
  })

  const getClinicTname = () => {
    if (i18next.language === 'th') {
      return clinic.value?.name
    }

    return clinic.value?.name_en ?? clinic.value?.name
  }

  const setUrl = (_url: string) => {
    url.value = _url
  }

  const setCrmUrl = (_crmUrl: string) => {
    crmUrl.value = _crmUrl
  }

  const setClinicUUID = (_clinicUUID: string) => {
    clinicUUID.value = stringToUUID(_clinicUUID)
  }

  const setLanguage = (_language: string) => {
    language.value = _language
  }

  const setUserProfile = (profile: LineLiffProfile) => {
    userProfile = profile
  }

  const setPatient = (__patient: PatientMini) => {
    patient.value = __patient

    localStorage.setItem('patient', JSON.stringify(__patient))
  }

  const setPatientFormCreate = (patient_form: PatientMini) => {
    patient.value = {
      uuid: null,
      title: patient_form.title,
      fname: patient_form.fname,
      fname_en: '',
      lname: patient_form.lname,
      lname_en: '',
      mobile: patient_form.mobile,
      branch_uuid: patient_form.branch_uuid,
      line_user_id: patient_form.line_user_id,
      allergy: patient_form.allergy,
      birthdate: patient_form.birthdate,
      nationality: patient_form.nationality,
      sex: patient_form.sex,
      terms_service: patient_form.terms_service,
      sms_agreement_appointment: patient_form.sms_agreement_appointment,
      sms_agreement_marketing: patient_form.sms_agreement_marketing,
      sms_agreement_no_marketing: patient_form.sms_agreement_no_marketing,
    }

    localStorage.setItem('patient', JSON.stringify(patient.value))
  }

  const setClinic = (__clinic: ClinicMini) => {
    clinic.value = __clinic
    localStorage.setItem('clinic', JSON.stringify(__clinic))
  }

  const getLocalPatient = (): PatientMini | null => {
    const get_storage_patient = localStorage.getItem('patient')
    return get_storage_patient ? JSON.parse(get_storage_patient) : null
  }

  const getLocalClinic = (): ClinicMini | null => {
    const get_storage_clinic = localStorage.getItem('clinic')
    return get_storage_clinic ? JSON.parse(get_storage_clinic) : null
  }

  // ============ features by course =============
  const sell_course: Ref<CourseLineSellFormated[] | null> = ref(null)
  const cart: Ref<CourseCart[]> = ref([])

  const setGlobalSellCourse = (course: CourseLineSellFormated[]) => {
    sell_course.value = course
  }

  const addCart = (course: CourseCart) => {
    cart.value.push(course)
  }

  const setCart = (__cart: CourseCart[]) => {
    cart.value = __cart
  }

  const removeFromCart = (course_uuid: string) => {
    const delete_index = cart.value.findIndex(
      course => course.course_uuid === course_uuid,
    )
    cart.value.splice(delete_index, 1)
  }

  const clearCart = () => {
    cart.value = []
  }

  const getCacheCoursePayment = (): CoursePayment | null => {
    const __payment = localStorage.getItem('course_payment')
    return __payment ? JSON.parse(__payment) : null
  }

  const getCacheCart = (): CourseCart[] | null => {
    const __cart = localStorage.getItem('cart')
    return __cart ? JSON.parse(__cart) : null
  }

  const createCacheCoursePayment = async (cache_data: CoursePaymentPayload) => {
    const payment = {
      create_date: new Date().toDateString(),
      payment: cache_data,
    }

    localStorage.setItem('course_payment', JSON.stringify(payment))
  }

  const createCacheCart = async () => {
    localStorage.setItem('cart', JSON.stringify(cart.value))
  }

  const clearCacheCoursePayment = () => {
    localStorage.setItem('course_payment', '')
  }

  const clearCacheCart = () => {
    localStorage.setItem('cart', '')
  }
  // ============ features by course =============

  return {
    url: computed(() => url.value),
    crmUrl: computed(() => crmUrl.value),
    clinicUUID: computed(() => clinicUUID.value),
    language: computed(() => language.value),
    sell_course: computed(() => sell_course.value),
    cart: computed(() => cart.value),
    patient: computed(() => patient.value),
    clinic: computed(() => clinic.value),
    getClinicTname,
    userProfile: computed(() => userProfile),
    setUrl,
    setCrmUrl,
    setClinicUUID,
    setLanguage,
    setUserProfile,
    setGlobalSellCourse,
    addCart,
    setCart,
    removeFromCart,
    clearCart,
    setPatient,
    getLocalPatient,
    getLocalClinic,
    setPatientFormCreate,
    setClinic,
    getCacheCoursePayment,
    getCacheCart,
    createCacheCoursePayment,
    createCacheCart,
    clearCacheCoursePayment,
    clearCacheCart,
  }
})
