<script lang="ts">
export default {
  name: 'ConfirmDialog',
  inheritAttrs: false,
  customOptions: {},
}
</script>

<script lang="ts" setup>
const props = defineProps([
  'show',
  'type',
  'title',
  'content',
  'confirmText',
  'cancelText',
  'onConfirm',
  'onCancel',
])
const styles = {
  width: '350px',
  'text-align': 'center',
  'padding-top': '24px',
  'padding-bottom': '24px',
  'padding-left': '50px',
  'padding-right': '50px',
  'border-radius': '20px',
}
</script>

<template>
  <van-popup :style="styles" :show="props.show">
    <h2 class="title">{{ props.title || 'Confirm?' }}</h2>
    <p v-if="props.content">{{ props.content }}</p>

    <slot name="button">
      <van-row justify="space-between">
        <van-col>
          <van-button type="danger" @click="() => props.onCancel()">{{
            props.cancelText ? props.cancelText : $t('cancel')
          }}</van-button>
        </van-col>
        <van-col>
          <van-button type="success" @click="() => props.onConfirm()">{{
            props.confirmText ? props.confirmText : $t('submit')
          }}</van-button>
        </van-col>
      </van-row>
    </slot>
  </van-popup>
</template>

<style scoped>
.title {
  margin-top: 0px;
}
</style>
