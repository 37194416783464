import axios from 'axios'
import i18next from 'i18next'
import th from './lang/th/th.json'
import en from './lang/en/en.json'
import namespacesTH from './lang/th/namespace.json'
import namespacesEN from './lang/en/namespace.json'
import { Locale } from 'vant'
import vantEnUS from 'vant/es/locale/lang/en-US'
import vantThTH from 'vant/es/locale/lang/th-TH'
import { storage } from '@/utils'
import { useAppStore } from '@/stores/AppStore'

export async function getPatientLanguage(): Promise<string> {
  try {
    // get from line_crm
    return await getUserLanguageInMongoDB()
  } catch {
    // get from localStorage
    const lsLang = storage.getItem('language')
    if (lsLang) return lsLang
    // get from env (fallback lng)
    return 'th'
  }
}

export async function setPatientLanguage(lang: string): Promise<void> {
  const appStore = useAppStore()
  appStore.setLanguage(lang)

  await i18next.changeLanguage(lang)
  i18next.language === 'th'
    ? Locale.use('th-TH', vantThTH)
    : Locale.use('en-US', vantEnUS)
  window.localStorage.setItem('language', lang)
  await updateUserLanguageInMongoDB(lang)
}

export async function i18nInitialized({ ns }: { ns: string }): Promise<void> {
  const appStore = useAppStore()
  await i18next.init({
    lng: appStore.language,
    fallbackLng: 'th',
    ns: ['translation', ...Object.keys(namespacesTH)],
    defaultNS: ns,
    fallbackNS: 'translation',
    resources: {
      th: { translation: th, ...namespacesTH },
      en: { translation: en, ...namespacesEN },
    },
  })
  i18next.language === 'th'
    ? Locale.use('th-TH', vantThTH)
    : Locale.use('en-US', vantEnUS)
}

async function updateUserLanguageInMongoDB(lang: string): Promise<void> {
  const appStore = useAppStore()
  try {
    await axios.post(`${appStore.crmUrl}/api/language`, {
      line_user_id: appStore.userProfile.userId,
      clinic_uuid: appStore.clinicUUID,
      language: lang,
    })
  } catch {}
}

async function getUserLanguageInMongoDB(): Promise<string> {
  const appStore = useAppStore()
  try {
    const res = await axios.get(`${appStore.crmUrl}/api/language`, {
      params: {
        line_user_id: appStore.userProfile.userId,
        clinic_uuid: appStore.clinicUUID,
      },
    })
    return res.data.language
  } catch {
    throw new Error('Cannot get language from line_crm')
  }
}
