<script setup lang="ts">
import { ref, reactive, onMounted, computed } from 'vue'

// type
import { Ref } from 'vue'
import { PatientMini, SexList, TitleList } from '@/type/Clinic'
import { PickerSelect } from '@/type/Vant'
import type { FormInstance, NotifyType } from 'vant'

// repo
import { useRouter } from 'vue-router'
import { useAppStore } from '@/stores/AppStore'
import { COUNTRIES } from '@/stores/Constant'
import { useClinicRepository } from '@/repositories/clinicRepo'

// components
import Footer from '@components/Footer.vue'
import { t } from 'i18next'

interface Snackbar {
  text: string
  type: NotifyType
}

const router = useRouter()
const appStore = useAppStore()
const repository = useClinicRepository(appStore.clinic!.uuid)

const form: PatientMini = reactive({
  uuid: '',
  branch_uuid: '',
  title: '',
  fname: '',
  fname_en: '',
  lname: '',
  lname_en: '',
  mobile: '',
  sex: '',
  birthdate: new Date(),
  nationality: 'TH',
  allergy: '',
  terms_service: false,
  sms_agreement_appointment: false,
  sms_agreement_marketing: false,
  sms_agreement_no_marketing: false,
  line_user_id: '',
})
const form_input: Ref<FormInstance | null> = ref(null)

const consent_text: Ref<{ sms_agreement_text: string } | null> = ref(null)
const consent_text_toggle: Ref<boolean> = ref(false)
const require_consent = computed(() => {
  if (consent_text.value?.sms_agreement_text) return true
  else false
})

// title picker
const title_list_toggle: Ref<boolean> = ref(false)
const title_list: Ref<TitleList[]> = ref([])
const display_title_list = computed(() => {
  return title_list.value.map(title => {
    return {
      text: title.name,
      value: title.name,
    }
  })
})
const onTitleSelect = (title: PickerSelect) => {
  form.title = title.selectedValues[0]
  title_list_toggle.value = false
}

// gender picker
const sex_list_toggle: Ref<boolean> = ref(false)
const sex_list: Ref<SexList[]> = ref([])
const display_sex_list = computed(() => {
  return sex_list.value.map(gender => {
    return {
      text: gender.name,
      value: gender.name,
    }
  })
})
const onSexSelect = (gender: PickerSelect) => {
  form.sex = gender.selectedValues[0]
  sex_list_toggle.value = false
}

// birthdate picker
const birthdate_picker_toggle: Ref<boolean> = ref(false)
const birthdate_display: Ref<string> = ref('')
const onBirthDaySelect = (date: PickerSelect) => {
  const day = date.selectedValues[0]
  const month = date.selectedValues[1]
  const year = date.selectedValues[2]

  form.birthdate = `${year}-${month}-${day}`
  birthdate_display.value = `${day}-${month}-${year}`
  birthdate_picker_toggle.value = false
}

// nationality picker
const nationality_list_toggle: Ref<boolean> = ref(false)
const nationality_display = computed(() => {
  return COUNTRIES.find(item => item.code === form.nationality)?.name ?? ''
})
const display_nationality_list = computed(() => {
  return COUNTRIES.map(nationality => {
    return {
      text: nationality.name,
      value: nationality.code,
    }
  })
})
const onNationalitySelect = (nationality: PickerSelect) => {
  form.nationality = nationality.selectedValues[0]
  nationality_list_toggle.value = false
}

// branch picker
// const branch_list_toggle: Ref<boolean> = ref(false)
// const branch_list: Ref<ClinicBranch[]> = ref([])
// const branch_display = ref('')
// const dispaly_branch_list = computed(() => {
//   return branch_list.value.map(branch => {
//     return {
//       text: branch.name,
//       value: branch.uuid
//     }
//   })
// })
// const onBranchSelect = (branch: PickerSelect) => {
//   branch_display.value = branch_list.value.find((item) => item.uuid === branch.selectedValues[0])?.name || ''
//   form.branch_uuid = branch.selectedValues[0]
//   branch_list_toggle.value = false
// }

// notify bar
const snackbar_toggle = ref(false)
const snackbar: Ref<Snackbar> = ref({ text: '', type: 'primary' })

const showSnackbar = (text: string, type: NotifyType = 'primary') => {
  snackbar_toggle.value = true
  snackbar.value.text = text
  snackbar.value.type = type
}

const closeSnackbar = () => {
  snackbar_toggle.value = false
  snackbar.value.text = ''
}

const validateText = (text: string) => {
  const pattern = /^[a-zA-Zก-ํ.'\s]+$/u
  return pattern.test(text)
}

const validateTerms = () => {
  if (require_consent && form.terms_service) {
    return true
  }

  return false
}

const validateMobile = (mobile: string) => {
  if (mobile.length >= 10 && mobile.length <= 15) return true
  return false
}

const validateForm = async () => {
  if (!form_input.value) return

  try {
    await form_input.value.validate()
    onSubmit()
  } catch (error) {
    // console.error(error)
  }
}

const verifyPatientMobile = async (
  patient_info: PatientMini,
): Promise<boolean> => {
  try {
    const { verify_status } = await repository.verifyPatientMobileDuplicate({
      mobile: patient_info.mobile,
    })
    if (!verify_status) {
      console.log('error')
      throw new Error(t('patient_create.error.mobile_verify_failed'))
    }

    return true
  } catch (error: Error | any) {
    showSnackbar(error.message, 'danger')
    setTimeout(() => {
      closeSnackbar()
    }, 3000)
    return false
  }
}

const onSubmit = async () => {
  // validateForm
  if (!form_input.value) return

  // validate patient data at backend
  if (!(await verifyPatientMobile(form))) return

  appStore.setPatientFormCreate({
    ...form,
    line_user_id: appStore.userProfile.userId,
  })

  showSnackbar(t('patient_create.created'), 'success')
  setTimeout(() => {
    closeSnackbar()
    router.replace({ name: 'CourseList' })
  }, 1500)
}

const onBack = () => {
  router.back()
}

onMounted(async () => {
  consent_text.value = await repository.fetchConsentFormAgreement()
  title_list.value = await repository.fetchTitleList()
  sex_list.value = await repository.fetchSexList()

  // branch_list.value = appStore.clinic!.branch
})
</script>

<template>
  <van-form ref="form_input" @submit="onSubmit">
    <div class="margin-y" style="--margin: 20px; text-align: center">
      <span class="clinic_branch">{{ $t('clinic') }}</span>
      <h2 class="margin-y" style="--margin: 0px">
        {{ appStore.getClinicTname() }}
      </h2>
    </div>

    <div style="overflow-y: auto; height: 80vh; margin-bottom: 80px">
      <p
        style="
          margin-top: 10px;
          margin-bottom: 20px;
          text-align: center;
          font-weight: bold;
        "
      >
        {{ $t('patient_create.patient_info') }}
      </p>

      <van-cell-group inset>
        <van-field
          v-model="form.title"
          is-link
          readonly
          :label="$t('patient_create.title')"
          :placeholder="$t('patient_create.title_placeholder')"
          @click="title_list_toggle = true"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
          ]"
        />
        <van-popup v-model:show="title_list_toggle" round position="bottom">
          <van-picker
            :confirm-button-text="$t('submit')"
            :cancel-button-text="$t('cancel')"
            :title="$t('patient_create.title')"
            :columns="display_title_list"
            @cancel="title_list_toggle = false"
            @confirm="onTitleSelect"
          />
        </van-popup>

        <van-field
          v-model="form.fname"
          type="text"
          :label="$t('patient_create.fname')"
          :placeholder="$t('patient_create.fname_placeholder')"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
            {
              required: true,
              message: $t('patient_create.error.invalid_text'),
              validator: validateText,
            },
          ]"
        />

        <van-field
          v-model="form.lname"
          :label="$t('patient_create.lname')"
          :placeholder="$t('patient_create.lname_placeholder')"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
            {
              required: true,
              message: $t('patient_create.error.invalid_text'),
              validator: validateText,
            },
          ]"
        />

        <van-field
          v-model="form.mobile"
          type="number"
          :label="$t('patient_create.mobile')"
          :placeholder="$t('patient_create.mobile_placeholder')"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
            {
              validator: validateMobile,
              message: $t('patient_create.mobile_invalid'),
            },
          ]"
        />

        <van-field
          v-model="form.sex"
          is-link
          readonly
          :label="$t('patient_create.gender')"
          :placeholder="$t('patient_create.gender_placeholder')"
          @click="sex_list_toggle = true"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
          ]"
        />
        <van-popup v-model:show="sex_list_toggle" round position="bottom">
          <van-picker
            :confirm-button-text="$t('submit')"
            :cancel-button-text="$t('cancel')"
            :title="$t('patient_create.gender')"
            :columns="display_sex_list"
            @cancel="sex_list_toggle = false"
            @confirm="onSexSelect"
          />
        </van-popup>

        <van-field
          v-model="birthdate_display"
          is-link
          readonly
          :label="$t('patient_create.birth_date')"
          :placeholder="$t('patient_create.birth_date_placeholder')"
          @click="birthdate_picker_toggle = true"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
          ]"
        />
        <van-popup
          v-model:show="birthdate_picker_toggle"
          round
          position="bottom"
        >
          <van-date-picker
            :confirm-button-text="$t('submit')"
            :cancel-button-text="$t('cancel')"
            :title="$t('patient_create.birth_date')"
            :min-date="new Date('1960-01-01')"
            :max-date="new Date()"
            :columns-type="['day', 'month', 'year']"
            @cancel="birthdate_picker_toggle = false"
            @confirm="onBirthDaySelect"
          />
        </van-popup>

        <van-field
          v-model="nationality_display"
          is-link
          readonly
          :label="$t('patient_create.nationality')"
          :placeholder="$t('patient_create.nationality_placeholder')"
          @click="nationality_list_toggle = true"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
          ]"
        />
        <van-popup
          v-model:show="nationality_list_toggle"
          round
          position="bottom"
        >
          <van-picker
            :confirm-button-text="$t('submit')"
            :cancel-button-text="$t('cancel')"
            :title="$t('patient_create.nationality')"
            :columns="display_nationality_list"
            @cancel="nationality_list_toggle = false"
            @confirm="onNationalitySelect"
          />
        </van-popup>

        <!-- <van-field
          v-model="branch_display"
          is-link
          readonly
          :label="$t('patient_create.branch')"
          :placeholder="$t('patient_create.branch_placeholder')"
          @click="branch_list_toggle = true"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
          ]"
        /> -->
        <!-- <van-popup
          v-model:show="branch_list_toggle"
          round
          position="bottom"
        >
          <van-picker
            :confirm-button-text="$t('submit')"
            :cancel-button-text="$t('cancel')"
            :title="$t('patient_create.branch')"
            :columns="dispaly_branch_list"
            @cancel="branch_list_toggle = false"
            @confirm="onBranchSelect"
          />
        </van-popup> -->

        <van-field
          v-model="form.allergy"
          type="textarea"
          rows="2"
          maxlength="255"
          autosize
          :label="$t('patient_create.allergy')"
          :placeholder="$t('patient_create.allergy_placeholder')"
          required
          :rules="[
            { required: true, message: $t('patient_create.error.required') },
          ]"
          show-word-limit
        />
      </van-cell-group>

      <p
        style="
          margin-top: 30px;
          margin-bottom: 30px;
          text-align: center;
          font-weight: bold;
        "
      >
        {{ $t('patient_create.sms_and_consent_form') }}
      </p>

      <van-cell-group inset>
        <van-field
          v-if="require_consent"
          :label="$t('patient_create.term_and_service')"
          placeholder="Align Top"
          label-align="top"
          required
          :rules="[
            {
              validator: validateTerms,
              message: $t('patient_create.error.must_accept'),
            },
          ]"
        >
          <template #button>
            <van-switch active-color="red" v-model="form.terms_service" />
          </template>
          <template #input>
            <van-button
              class="margin-y"
              style="--margin: 10px"
              @click="consent_text_toggle = true"
              type="default"
              >{{ $t('patient_create.read_consent_form') }}</van-button
            >
          </template>
        </van-field>

        <van-cell :title="$t('patient_create.SMS_Noti_consent')">
          <template #right-icon>
            <van-switch v-model="form.sms_agreement_appointment" />
          </template>
        </van-cell>

        <van-cell :title="$t('patient_create.SMS_Promo_consent')">
          <template #right-icon>
            <van-switch v-model="form.sms_agreement_marketing" />
          </template>
        </van-cell>

        <van-cell :title="$t('patient_create.SMS_Info_consent')">
          <template #right-icon>
            <van-switch v-model="form.sms_agreement_no_marketing" />
          </template>
        </van-cell>
      </van-cell-group>
    </div>

    <Footer :styles="{ 'background-color': 'white', padding: '10px' }">
      <van-row>
        <van-col @click="onBack" span="6" style="align-self: center">
          <van-icon name="clear" size="40" color="red" />
        </van-col>
        <van-col span="17"
          ><van-button
            style="margin-top: 5px"
            type="primary"
            block
            @click="validateForm"
            :loading="snackbar_toggle"
          >
            {{ $t('patient_create.register') }}
          </van-button>
        </van-col>
      </van-row>
    </Footer>

    <van-popup :show="consent_text_toggle">
      <div class="dialog-box">
        <h2 style="margin-top: 0px">Term & Service</h2>
        <div
          class="margin-y"
          style="--margin: 20px; max-height: 60vh; overflow-y: auto"
        >
          <p v-html="consent_text?.sms_agreement_text"></p>
        </div>
        <van-button
          style="padding-left: 30px; padding-right: 30px"
          type="primary"
          @click="consent_text_toggle = false"
          >{{ $t('close') }}</van-button
        >
      </div>
    </van-popup>
  </van-form>

  <van-notify v-model:show="snackbar_toggle" :type="snackbar.type">
    <span>{{ snackbar.text }}</span>
  </van-notify>
</template>

<style scoped>
.link-text {
  color: blue;
}
.margin-y {
  margin-top: var(--margin);
  margin-bottom: var(--margin);
}

.dialog-box {
  word-wrap: break-word;
  width: 80vw;
  padding: 20px;
  text-align: center;
}
</style>
