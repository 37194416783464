<script setup lang="ts">
import { useBFRepository } from '@/repositories/bfRepo'
import { BFAlbum } from '@/type/BFAlbum'
import { computed, Ref, ref, onMounted } from 'vue'
import BFPicture from './BFPicture.vue'
import { getParamsFromLiffUrl } from '@/utils'

// constant
interface taglist {
  name: string
  active: boolean
}

const emptyAlbum: BFAlbum = {
  id: 0,
  name: '',
  create_by_name: '',
  update_by_name: '',
  create_date: null,
  update_date: null,
  pictures: [],
  edit: false,
  tags: [''],
  patient: 0,
  patient_name: '',
  type: '',
  showcase_flag: true,
}
// const chunksize = 2
const chunksize = Math.floor(window.innerWidth / 200)

// data
const loading: Ref<boolean> = ref(false)
const taglist: Ref<taglist[]> = ref([])
const albums: Ref<BFAlbum[]> = ref([])
const popup_data: Ref<BFAlbum | null> = ref(null)

// ui toggle
const toggle_tags: Ref<any> = ref(null) // dunno it's type
const popup: Ref<boolean> = ref(false)

// computed
const table = computed(() => {
  if (!albums.value || albums.value.length == 0) return

  const filter = taglist.value.filter(tag => tag.active) // filter only active tag
  const tag_array = filter.map(item => item.name) // get name value from tag array

  let _album = [...albums.value].filter(album => album.pictures.length > 0)
  if (tag_array.length > 0) {
    // filter if tag is active
    _album = _album.filter(({ tags }) =>
      tags.some(item => tag_array.includes(item)),
    )
  }

  const chunk = []
  for (let i = 0; i < _album.length; i += chunksize) {
    chunk.push(_album.slice(i, i + chunksize))
  }

  if (chunk[chunk.length - 1].length % chunksize < chunksize) {
    const fill_in_size = chunksize - chunk[chunk.length - 1].length
    for (let i = 0; i < fill_in_size; i++) {
      chunk[chunk.length - 1].push(emptyAlbum)
    }
  }

  return chunk
})
const span = computed(() => {
  return Math.floor(24 / chunksize)
})

// method
function onSwitch(tag_name: string) {
  const tag_filter = taglist.value.find(({ name }) => name === tag_name)
  if (!tag_filter) return // just prevent an error
  tag_filter.active = !tag_filter.active
}

function onConfirm() {
  toggle_tags.value.toggle()
}

function openPopup(album_id: number) {
  if (album_id === 0) return

  popup.value = true
  const filtered = albums.value.filter(album => album.id === album_id)[0]

  popup_data.value = filtered
}

function closePopup() {
  popup.value = false
}

// mount
onMounted(async () => {
  loading.value = true
  const clinic_uuid = getParamsFromLiffUrl(window.location.href).get('c') || ''
  const repository = useBFRepository(clinic_uuid)
  const _tags = await repository.fetchTags()
  taglist.value = _tags.map((tag: string) => {
    return {
      name: tag,
      active: false,
    }
  })
  albums.value = await repository.fetchBFAlbum()
  loading.value = false
})
</script>

<template>
  <div>
    <van-divider>Showcase Album</van-divider>

    <div style="margin: 20px">
      <van-dropdown-menu>
        <van-dropdown-item :title="$t('bf_showcase.filter')" ref="toggle_tags">
          <van-cell
            v-for="{ name, active } in taglist"
            :key="name"
            center
            :title="name"
          >
            <template #right-icon>
              <van-switch
                :model-value="active"
                @change="onSwitch(name)"
                size="24"
                active-color="#ee0a24"
              />
            </template>
          </van-cell>
          <div style="padding: 5px 16px">
            <van-button type="danger" block round @click="onConfirm">
              Confirm
            </van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>

      <div v-show="loading" style="text-align: center; margin-top: 10%">
        <van-loading size="40px">Loading...</van-loading>
      </div>

      <div
        v-if="!loading && albums && albums.length === 0"
        style="text-align: center; margin-top: 10%"
      >
        <p>{{ $t('bf_showcase.no_preview_album') }}</p>
      </div>

      <van-row
        v-else
        v-for="(row, num_row) in table"
        :key="`row ${num_row}`"
        type="flex"
        gutter="20"
        justify="left"
      >
        <van-col
          v-for="(col, num_col) in row"
          :key="`col ${num_col}`"
          style="margin-top: 20px"
          :span="span"
          @click="() => openPopup(col.id)"
        >
          <div v-if="col.id === 0"></div>

          <div v-else>
            <div
              :style="{
                minHeight: 150,
                aspectRatio: 1 / 1,
                textAlign: 'center',
                borderRadius: '10px',
                border: '1px #dddddd solid',
              }"
            >
              <van-image
                radius="10"
                height="100%"
                width="100%"
                fit="contain"
                :src="col.pictures[0].pic_thumb"
              />
            </div>
            <div style="margin-left: 20px; margin-top: 10px; text-align: left">
              <span
                style="
                  display: block;
                  overflow: hidden;
                  width: 100px;
                  text-overflow: ellipsis;
                "
                >{{ col.name }}</span
              >
              <span style="color: #aaaaaa">{{ col.pictures.length }}</span>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>

    <BFPicture
      @close="closePopup"
      :visible="popup"
      :album="popup_data || emptyAlbum"
    />
  </div>
</template>

<style scoped>
.van-grid-item__content {
  padding: 0px !important;
  background-color: black !important;
}
</style>
