<script lang="ts">
export default {
  inheritAttrs: false,
  customOptions: {},
}
</script>

<script lang="ts" setup>
const props = defineProps(['show'])
</script>

<template>
  <!-- loading -->
  <van-popup
    style="text-align: center; padding: 48px; border-radius: 20px"
    :show="props.show"
  >
    <slot>
      <slot name="top"></slot>
      <van-loading color="green" text-color="black" size="48px" vertical>
        <slot name="text"> Loading... </slot>
      </van-loading>
    </slot>
  </van-popup>
</template>
