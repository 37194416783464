<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { setPatientLanguage } from '@/i18n'
import { useAppStore } from '@/stores/AppStore'
const appStore = useAppStore()
const props = defineProps<{
  title: string
}>()

const lang = ref('')

onMounted(async () => {
  lang.value = appStore.language
})

const langText = computed(() => {
  return lang?.value.toLowerCase() === 'en' ? 'ไทย' : 'EN'
})

function onClickRight() {
  lang.value = lang.value === 'en' ? 'th' : 'en'
  setPatientLanguage(lang.value)
}
</script>

<template>
  <van-nav-bar :right-text="langText" @click-right="onClickRight">
    <template #left>
      <slot name="left"></slot>
    </template>

    <template #title>
      <slot name="title">
        {{ props.title }}
      </slot>
    </template>
  </van-nav-bar>
</template>
