<script lang="ts" setup>
import { onMounted } from 'vue'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { format } from 'date-fns'
import router from '@/router'

// components
import ConfirmDialog from '@components/ConfirmDialog.vue'

// type
import { Ref } from 'vue'
import { CourseCart, CoursePayment } from '@/type/Course'
import { PatientMini } from '@/type/Clinic'

// repo
import { useAppStore } from '@/stores/AppStore'
import liff from '@line/liff'
import { getParamsFromLiffUrl } from '@/utils'
import { useClinicRepository } from '@/repositories/clinicRepo'
// import liff from '@line/liff'

const appStore = useAppStore()
const route = useRoute()

const loading_button = ref<boolean>(false)
const payment: Ref<CoursePayment | null> = ref(null)
const cart: Ref<CourseCart[] | null> = ref([])
const patient: Ref<PatientMini | null> = ref(null)
const status: Ref<'success' | 'failure'> = ref('failure')
const params = getParamsFromLiffUrl(window.location.href)

const date_format = computed(() => {
  if (payment.value) {
    return format(new Date(payment.value.create_date!), 'dd-MM-yyyy')
  }

  return ''
})

const paid_amount = computed(() => {
  if (payment.value) {
    return payment.value.payment.payment.paid_amount
  }

  return '-'
})

const goBackToCourseList = async () => {
  loading_button.value = true
  if (!appStore.patient?.uuid) {
    await getNewPatientRealData()
  }

  appStore.clearCacheCoursePayment()
  loading_button.value = false
  router.replace({ name: 'CourseList' })
}
const goToAppointment = () => {
  router.replace({
    name: 'Appointment',
    query: {
      c: appStore.clinic?.uuid,
      l: appStore.patient?.line_user_id,
      liffId: params.get('liffId'),
      clinicType: params.get('clinicType'),
      from: 'course',
    },
  })
}
const closeLiff = () => {
  liff.closeWindow()
}
const retry = () => {
  appStore.setCart(cart.value!)
  router.replace({ name: 'CourseCheckout' })
}

const confirm_dialog: Ref<'list' | 'appointment' | 'close' | null> = ref(null)
const openConfirmDialog = (goTo: 'list' | 'appointment' | 'close') => {
  confirm_dialog.value = goTo
}
const closeConfirmDialog = () => {
  confirm_dialog.value = null
}
const onConfirmDialog = () => {
  switch (confirm_dialog.value) {
    case 'list':
      return goBackToCourseList()
    case 'appointment':
      return goToAppointment()
    case 'close':
      return closeLiff()
    default:
      return
  }
}

const getNewPatientRealData = async () => {
  const clinic_repository = useClinicRepository(appStore.clinicUUID)
  try {
    const patient = await clinic_repository.fetchPatientMiniInfo()
    localStorage.setItem('patient', JSON.stringify(patient))
    appStore.setPatient(patient)
  } catch (error) {
    console.error(error)
  }
}

onMounted(async () => {
  const get_patient = appStore.getLocalPatient()
  const get_clinic = appStore.getLocalClinic()
  const _payment = appStore.getCacheCoursePayment()
  const _cart = appStore.getCacheCart()

  if (!_payment || !_cart) {
    status.value = 'failure'
    return
  }

  payment.value = _payment
  cart.value = _cart
  patient.value = get_patient

  appStore.setClinic(get_clinic!) // can't be null or it error
  appStore.setPatient(get_patient!) // can't be null or it error

  if (route.params.status === 'success') {
    status.value = 'success'
    appStore.clearCart()
  }
})
</script>

<template>
  <div style="text-align: center; margin-top: 20px">
    <div v-if="status === 'success'">
      <h3 style="margin-bottom: 0px">
        {{ $t('course.buy_course.thank_for_purchase') }}
      </h3>
      <p style="margin-top: 0px">
        * {{ $t('course.buy_course.download_receipt') }} *
      </p>
    </div>

    <div v-else>
      <h3 style="margin-bottom: 0px; color: red">
        {{ $t('course.error.failed_payment') }}
      </h3>
      <p style="margin-top: 0px">
        {{ $t('course.error.failed_payment_description') }}
      </p>
    </div>
  </div>

  <div style="margin-top: 10px">
    <p style="text-align: center; font-weight: bold">
      {{ $t('course.buy_course.list') }}
    </p>
    <div style="background-color: white" class="box">
      <van-list v-if="cart?.length">
        <div class="list">
          <van-card
            v-for="item in cart"
            style="background-color: white"
            :key="item.name"
            :title="item.name"
            currency="฿"
            :price="item.is_deposit ? item.deposit_cost : item.price"
            :thumb="item.image_url"
          />
        </div>
      </van-list>

      <p v-else style="text-align: center">
        {{ $t('course.error.no_buy_item_error') }}
      </p>
    </div>
  </div>

  <div style="margin-top: 10px">
    <p style="text-align: center; font-weight: bold">
      {{ $t('course.buy_course.detail') }}
    </p>
    <van-cell-group class="box">
      <van-cell
        :title="$t('patient.fullname')"
        :value="`${patient?.fname} ${patient?.lname}`"
      />
      <van-cell :title="$t('patient_create.mobile')" :value="patient?.mobile" />
      <van-cell :title="$t('course.BUY_DATE')" :value="date_format" />
      <van-cell
        :title="$t('course.buy_course.total_price')"
        :value="`${paid_amount}.-`"
      />
    </van-cell-group>
  </div>

  <div style="margin-top: 30px; margin-left: 20px; margin-right: 20px">
    <div v-if="status === 'success'">
      <van-button
        @click="openConfirmDialog('list')"
        type="success"
        class="button"
        block
        :loading="loading_button"
      >
        {{ $t('course.buy_course.to_course_list') }}
      </van-button>
      <van-button
        @click="openConfirmDialog('appointment')"
        style="background-color: orange"
        class="button"
        block
      >
        {{ $t('course.buy_course.to_course_appointment') }}
      </van-button>
    </div>

    <div v-else>
      <van-button @click="retry" type="danger" class="button" block>
        {{ $t('course.buy_course.retry_payment') }}
      </van-button>
    </div>

    <div @click="openConfirmDialog('close')">
      <p style="color: #aaaaaa; text-align: center; text-decoration: underline">
        {{ $t('close') }}
      </p>
    </div>
  </div>

  <ConfirmDialog
    :show="confirm_dialog !== null"
    :content="$t('course.buy_course.exit_dialog')"
    :onConfirm="onConfirmDialog"
    :onCancel="closeConfirmDialog"
  />
</template>

<style scoped>
.box {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.list {
  max-height: 300px;
  overflow-y: auto;
}

.button {
  margin-top: 10px;
}
</style>
