<script lang="ts">
export default {
  inheritAttrs: false,
  customOptions: {},
}
</script>

<script setup lang="ts">
  const props = defineProps(['styles'])
</script>

<template>
  <div :style="props.styles" class="footer">
    <slot>
      <van-button type="default">{{ $t('submit') }}</van-button>
    </slot>
  </div>
</template>

<style scoped>
.footer {
  justify-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: #555555;
  text-align: center;
}
</style>
