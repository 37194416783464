import {
  BackendCoursePrice,
  Course,
  CourseCart,
  CourseLineSell,
  CourseLineSellFormated,
  CoursePaymentPayload,
  FetchCourseLineSell,
  PatientCourseQuota,
} from '@/type/Course'
import axios from 'axios'
import { useAppStore } from '@/stores/AppStore'
import { PaymentLink, paymentMethod } from '@/type/Payment'
import currency from 'currency.js'
import { $currency } from '@/global'

export const formatSellCourseWithQuota = (
  course_list: CourseLineSell[],
  patient_course_quota?: PatientCourseQuota[],
): CourseLineSellFormated[] => {

  // กรณียังไม่ login ให้ถือว่า ทุก buy_count = 0
  if (!patient_course_quota || patient_course_quota.length === 0) {
    return course_list.map(course => {
      return {
        ...course,
        buy_count: 0,
      }
    })
  }

  // กรณี login แล้ว
  const formatted: CourseLineSellFormated[] = course_list.map(course => {
    const find = patient_course_quota.find(
      quota =>
        quota.course_name === course.name && quota.course_code === course.code,
    )

    if (find) {
      return {
        ...course,
        buy_count: find.count,
      }
    }

    return {
      ...course,
      buy_count: 0,
    }
  })

  // buy_limit = 0 ไม่จำกัดจำนวนครั้งการซื้อ หรือ จำนวนครั้งการซื้อ ยังไม่เกิน limit
  return formatted.filter(
    course => course.buy_limit === 0 || course.buy_count < course.buy_limit,
  )
}

export function useCourseRepository(clinic_uuid: string) {
  const appStore = useAppStore()

  async function fetchCourse(course_uuid: string): Promise<Course> {
    try {
      const response = await axios.get(
        `${appStore.url}/api/v1/crm/line-app/p/patient-course/${course_uuid}/?c=${clinic_uuid}&l=${appStore.userProfile.userId}`,
      )

      if (response.data.code) {
        throw new Error(response.data.msg)
      }

      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async function fetchSellCourseList(): Promise<FetchCourseLineSell> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/sell-course/list/?c=${appStore.clinic?.uuid}`,
      {
        params: {
          patient_uuid: appStore.patient?.uuid,
        },
      },
    )

    return response.data
  }

  async function createPaymentLink(
    course_cart: CourseCart[],
    branch_uuid: string,
    paid_amount: number,
    payment_method: paymentMethod,
  ): Promise<PaymentLink> {
    if (!appStore.clinic) {
      throw new Error('no clinic data')
    }

    if (!appStore.patient) {
      throw new Error('no patient data')
    }

    // format data before adding to payload
    let total_fee = $currency(0)
    let total_vat = $currency(0)
    let total_price = $currency(0)
    const courses: BackendCoursePrice[] = course_cart.map(item => {
      total_fee = total_fee.add(item.fee)
      total_vat = total_vat.add(item.vat)
      total_price = total_price.add(item.price)

      return {
        course_uuid: item.course_uuid,
        remark: null,
        debt: item.is_deposit
          ? currency(item.price).subtract(item.deposit_cost).value
          : 0, // ถ้าซื้อมัดจำ ก็จะมี debt : ถ้าไม่ ก็เป็น 0
        disc_price: item.price, // ราคาเต็ม เพราะว่าไม่มีส่วนลดอะไร ในตอนนี้
        price: item.price,
        paid_amount: item.is_deposit ? item.deposit_cost : item.price, // ถ้าซื้อมัดจำ ก็จะจ่ายแค่ราคามัดจำ : ถ้าไม่ ก็จ่ายราคาเต็ม
        payment_discounts: [], // ไม่มีส่วนลดในตอนนี้
        vat: currency(item.vat, { precision: 2 }).value, // ปัด ถ้าเกิน 2 ตำแหน่ง backend ระเบิด
        fee: currency(item.fee, { precision: 2 }).value, // ปัด ถ้าเกิน 2 ตำแหน่ง backend ระเบิด
      }
    })

    const payload: CoursePaymentPayload = {
      patient: { ...appStore.patient },
      payment: {
        courses,
        vat: currency(total_vat.value, { precision: 2 }).value,
        vat_rate: appStore.clinic.course_VAT_rate,
        vat_type: appStore.clinic.course_VAT_type,
        fee: currency(total_fee.value, { precision: 2 }).value,
        fee_rate: appStore.clinic.course_fee_rate,
        fee_type: appStore.clinic.course_fee_type,
        total: currency(total_price.value, { precision: 2 }).value,
        paid_amount: currency(paid_amount, { precision: 2 }).value, // ปัด ถ้าเกิน 2 ตำแหน่ง backend ระเบิด
        branch_uuid: branch_uuid,
        payment_method: payment_method,
      },
    }

    const response = await axios.post(
      `${appStore.url}/api/v1/crm/line-app/p/course-payment-link/?c=` +
        appStore.clinic.uuid,
      payload,
    )

    return { ...response.data, cache_data: payload }
  }

  async function checkIsCourseBuyValid(course_uuids: string[]) {
    const response = await axios.post(
      `${appStore.url}/api/v1/crm/line-app/p/verify/patient/buy-course-quota/`,
      {
        patient_uuid: appStore.patient?.uuid,
        line_user_id: appStore.patient?.line_user_id,
        course_uuids: course_uuids,
      },
    )

    return response
  }

  return {
    fetchCourse,
    fetchSellCourseList,
    createPaymentLink,
    checkIsCourseBuyValid,
  }
}
