<script lang="ts" setup>
import { onMounted } from 'vue'
import { ref, computed } from 'vue'
import router from '@/router'
import { SwipeCellInstance } from 'vant'
import currency from 'currency.js'

// components
import LoadingDialog from '@components/LoadingDialog.vue'
import Footer from '@components/Footer.vue'
import ConfirmDialog from '@components/ConfirmDialog.vue'
import CourseDialog from '@/components/CourseCheckout/CourseDialog.vue'

// type
import { Ref } from 'vue'
import { CourseCart } from '@/type/Course'
import { ClinicBranch, ClinicMini, PatientMini } from '@/type/Clinic'
import { PickerSelect } from '@/type/Vant'
import { paymentMethod, paymentDisplay } from '@/type/Payment'

// repo
import { useAppStore } from '@/stores/AppStore'
import { useCourseRepository } from '@/repositories/courseRepo'
import { t } from 'i18next'
import { useClinicRepository } from '@/repositories/clinicRepo'
import { $currency, useCurrencyDisplay } from '@/global/index'

const appStore = useAppStore()
const courseRepo = useCourseRepository(appStore.clinic?.uuid!)
const clinicRepo = useClinicRepository(appStore.clinic?.uuid!)

const course: Ref<CourseCart[] | null> = ref([])
const patient: Ref<PatientMini | null> = ref(null)
const clinic: Ref<ClinicMini | null> = ref(null)

const excludeFormular = (amount: currency, rate: currency) => {
  return amount.multiply(rate.multiply(0.01)).value
}

const includeFormular = (amount: currency, rate: currency) => {
  // (price * vat) / (100 + vat) = include_vat
  const __result = amount.multiply(rate)
  const divider = $currency(100).add(rate)

  return __result.divide(divider).value
}

const calculate_fee = (amount: currency) => {
  if (!appStore.clinic) {
    throw new Error('no clinic found')
  }

  if (!appStore.clinic.course_fee_type) {
    return 0
  }

  if (appStore.clinic?.course_fee_type === 'INC') {
    // case include
    return includeFormular(amount, $currency(appStore.clinic?.course_fee_rate))
  }

  if (appStore.clinic?.course_fee_type === 'EXC') {
    // case exclude
    return excludeFormular(amount, $currency(appStore.clinic?.course_fee_rate))
  }

  throw new Error('fee_type is null')
}

const calculate_vat = (amount: currency) => {
  if (!appStore.clinic) {
    throw new Error('no clinic found')
  }

  if (!appStore.clinic.course_VAT_type) {
    return 0
  }

  if (appStore.clinic?.course_VAT_type === 'INC') {
    // case include
    return includeFormular(amount, $currency(appStore.clinic?.course_VAT_rate))
  }

  if (appStore.clinic?.course_VAT_type === 'EXC') {
    // case exclude
    return excludeFormular(amount, $currency(appStore.clinic?.course_VAT_rate))
  }

  throw new Error('VAT_type is null')
}

const priceSum = computed(() => {
  if (course.value) {
    const sum = course.value.reduce((total, currentValue) => {
      let cost = currentValue.is_deposit
        ? $currency(currentValue.deposit_cost)
        : $currency(currentValue.price)

      let __total = $currency(total).add(cost)

      // VAT
      if (appStore.clinic?.course_VAT_type) {
        currentValue.vat = calculate_vat(cost)

        if (appStore.clinic?.course_VAT_type === 'EXC') {
          __total = __total.add(currentValue.vat)
          cost = cost.add(currentValue.vat)
        }
      }

      // ถ้าเลือกใช้บัตรเครดิตในการจ่ายก็จะคำนวณ fee ทันที
      if (
        paymentMethods.value === 'GBPRIMEPAY_CREDIT_CARD' &&
        appStore.clinic?.course_fee_type
      ) {
        currentValue.fee = calculate_fee(cost)

        if (appStore.clinic?.course_fee_type === 'EXC') {
          __total = __total.add(currentValue.fee)
        }
      }

      return __total
    }, $currency(0))

    return sum
  }

  return $currency(0)
})

const feeSum = computed(() => {
  if (
    course.value &&
    paymentMethods.value === 'GBPRIMEPAY_CREDIT_CARD' &&
    appStore.clinic?.course_fee_type
  ) {
    const sum = course.value.reduce((total, currentValue) => {
      return $currency(total).add(currentValue.fee)
    }, $currency(0))

    return sum
  }

  return $currency(0)
})

const vatSum = computed(() => {
  if (course.value && appStore.clinic?.course_VAT_type) {
    const sum = course.value.reduce((total, currentValue) => {
      return $currency(total).add(currentValue.vat)
    }, $currency(0))

    return sum
  }

  return $currency(0)
})

const feeIncOrExc = computed(() => {
  if (paymentMethods.value === 'GBPRIMEPAY_CREDIT_CARD') {
    if (appStore.clinic?.course_fee_type === 'INC') {
      return '(include)'
    }

    if (appStore.clinic?.course_fee_type === 'EXC') {
      return '(exclude)'
    }
  }

  return ''
})

const VATIncOrExc = computed(() => {
  if (appStore.clinic?.course_VAT_type === 'INC') {
    return '(include)'
  }

  if (appStore.clinic?.course_VAT_type === 'EXC') {
    return '(exclude)'
  }

  return ''
})

const swipeCard = ref<SwipeCellInstance[]>([])

// payment method picker
const paymentMethods: Ref<paymentMethod | null> = ref(null)
const paymentDisplays: Ref<paymentDisplay | ''> = ref('')
const paymentList = ref([
  { name: 'QR CODE', value: 'GBPRIMEPAY_QRCASH' },
  { name: 'CREDIT CARD', value: 'GBPRIMEPAY_CREDIT_CARD' },
])
const paymentListToggle = ref(false)
const displayPaymentList = computed(() => {
  return paymentList.value.map(method => {
    return {
      text: method.name,
      value: method.value,
    }
  })
})
const onPaymentSelect = (method: PickerSelect) => {
  paymentMethods.value = method.selectedValues[0]
  paymentDisplays.value = method.selectedOptions[0].text
  paymentListToggle.value = false
}

// branch picker
const selectedBranch = ref('')
const branchDisplay = ref('')
const branchListToggle: Ref<boolean> = ref(false)
const branchList: Ref<ClinicBranch[]> = ref([])
const dispalyBranchList = computed(() => {
  return branchList.value.map(branch => {
    return {
      text: branch.text,
      value: branch.uuid,
    }
  })
})
const onBranchSelect = (branch: PickerSelect) => {
  branchDisplay.value = branch.selectedOptions[0].text
  selectedBranch.value = branch.selectedValues[0]

  // ถ้าเป็นลูกค้าใหม่ branch ที่ assign ใส่ patient จะเป็น branch แรกที่สั่งซื้อคอร์ส
  if (!patient.value?.branch_uuid) {
    appStore.setPatient({
      ...patient.value!,
      branch_uuid: branch.selectedValues[0],
    })
  }

  branchListToggle.value = false
}

const loading = ref(false)

const confirmDialog = ref(false)
const confirmDialogType = ref(0)
const confirmDialogTitle = ref('')
const confirmDialogContent = ref('')
const confirmButtonText = ref('')
const confirmButtonLoading = ref(false)
const cancelButtonText = ref('')
let onConfirm = () => {}
let onCancel = () => {}

const openDialogOnClearCart = () => {
  if (course.value?.length === 0) return clearCart()
  confirmDialogType.value = 1
  confirmDialogTitle.value = `${t('submit')}?`
  confirmDialogContent.value = t('course.buy_course.cancel_checkout_dialog')
  confirmButtonText.value = t('submit')
  cancelButtonText.value = t('cancel')
  onCancel = () => {
    confirmDialog.value = false
  }
  onConfirm = () => {
    clearCart()
  }
  confirmDialog.value = true
}

const openDialogOnExistingPayment = async (existing_payment_link: string) => {
  // fetch Unfinished Payment
  confirmDialogType.value = 2
  confirmDialogTitle.value = t('redirect.unfinished_payment_title')
  confirmDialogContent.value = t('redirect.unfinished_payment_content')
  confirmButtonText.value = t('redirect.unfinished_payment_confirm')
  cancelButtonText.value = t('redirect.unfinished_payment_cancel')
  onConfirm = async () => {
    if (confirmButtonLoading.value) {
      return
    }
    confirmButtonLoading.value = true

    await validateExistingPaymentAPI() // verify again before create new like

    try {
      await clinicRepo.cancelPayment()
    } catch (error) {
      throw new Error('Cancel Payment Error')
    }
    await checkout()
    confirmButtonLoading.value = false
  }
  onCancel = () => {
    window.location.replace(existing_payment_link)
  }
  confirmDialog.value = true
}

const removeItem = (uuid: string) => {
  appStore.removeFromCart(uuid)
}

const clearCart = () => {
  appStore.clearCart()
  router.replace({ name: 'CourseList' })
}
const courseHaveBeenRemoveDialogData = ref<CourseCart[]>([])
const courseHaveBeenEditDialogData = ref<
  { old: CourseCart; new: CourseCart }[]
>([])
const courseHaveBeenRemoveDialogToggle = ref(false)
const updateCourseDataOnDialogClose = () => {
  loading.value = true
  // remove the removed
  const newCourseData = course.value!.filter(cart =>
    courseHaveBeenRemoveDialogData.value.every(
      filter => cart.course_uuid !== filter.course_uuid,
    ),
  )
  // update edited data
  courseHaveBeenEditDialogData.value.forEach(updatedItem => {
    newCourseData.forEach((item, index) => {
      if (item.course_uuid === updatedItem.old.course_uuid) {
        newCourseData[index] = {
          name: updatedItem.new.name,
          course_uuid: updatedItem.old.course_uuid,
          deposit_cost: updatedItem.new.detail.liff_data.pledge_cost,
          detail: updatedItem.new.detail,
          image_url: updatedItem.new.detail.liff_data.image_url,
          price: updatedItem.new.detail.liff_data.cost_on_LINE,
          is_deposit: newCourseData[index].is_deposit,
          vat: 0,
          fee: 0,
        } // update by mutate object inside newCourseData
      }
    })
  })
  // new data
  course.value = [...newCourseData]
  // clear data
  courseHaveBeenRemoveDialogData.value = []
  courseHaveBeenEditDialogData.value = []
  courseHaveBeenRemoveDialogToggle.value = false
  loading.value = false
}
const validateExistingCourse = async () => {
  if (!course.value) return false
  // 2. check is courses have been edit
  try {
    let validateStatus = true
    const newCoursesData = (await courseRepo.fetchSellCourseList()).sell_course // get new course sell data
    course.value.forEach(cart => {
      // 1. check is courses still existing
      const findCourse = newCoursesData.find(
        item => item.uuid === cart.course_uuid,
      )

      if (!findCourse) {
        // not existing anymore
        courseHaveBeenRemoveDialogData.value = [
          ...courseHaveBeenRemoveDialogData.value,
          cart,
        ]

        validateStatus = false
      } else {
        // still existing then check it's data [name, price, pledge_price]
        if (
          cart.name !== findCourse.name ||
          cart.detail.liff_data.cost_on_LINE !==
            findCourse.liff_data.cost_on_LINE ||
          cart.detail.liff_data.pledge_cost !== findCourse.liff_data.pledge_cost
        ) {
          courseHaveBeenEditDialogData.value = [
            ...courseHaveBeenEditDialogData.value,
            {
              new: {
                detail: findCourse,
                course_uuid: findCourse.uuid,
                deposit_cost: findCourse.liff_data.pledge_cost,
                name: findCourse.name,
                image_url: findCourse.liff_data.image_url,
                // not use
                fee: 0,
                price: 0,
                is_deposit: false,
                vat: 0,
              },
              old: cart,
            },
          ]
          validateStatus = false
        }
      }
    })

    // toggle course have been remove
    if (!validateStatus) {
      courseHaveBeenRemoveDialogToggle.value = true
    }

    return validateStatus
  } catch (error) {
    console.error(error)
    return false
  }
}

const validateBuyCourseQuota = async () => {
  try {
    if (!course.value) throw new Error('No course error in quota check')

    // no patient uuid = new patient = totally have quota for sure
    if (!appStore.patient?.uuid) return true

    await courseRepo.checkIsCourseBuyValid(course.value.map(item => item.course_uuid))
    return true
  } catch (error) {
    console.error(error)
    alert(t('course.buy_course.quota_limit_error'))
    return false
  }
}

const validateExistingPaymentAPI = async () => {
  try {
    const { has_exist, existing_payment_link } =
      await clinicRepo.verifyPatientPayment()

    if (has_exist) {
      openDialogOnExistingPayment(existing_payment_link)
      return false
    }

    return true
  } catch (error) {
    console.error('Payment Check Error')
    alert(t('course.buy_course.payment_duplicate_error'))
    confirmDialog.value = false
    throw new Error('Payment Check Error')
  }
}

const validator = async () => {
  loading.value = true
  if (
    (await validateExistingPaymentAPI()) &&
    (await validateExistingCourse()) &&
    (await validateBuyCourseQuota())
  ) {
    await checkout()
  }
  loading.value = false
}

const checkout = async () => {
  const { result, link, cache_data } = await courseRepo.createPaymentLink(
    course.value!,
    selectedBranch.value,
    priceSum.value.value,
    paymentMethods.value!,
  )
  appStore.createCacheCoursePayment(cache_data) // save cache to local storage
  appStore.createCacheCart()

  if (result === 'success') {
    window.location.href = link
    return
  }
}

onMounted(async () => {
  course.value = appStore.cart
  patient.value = appStore.patient
  clinic.value = appStore.clinic
  selectedBranch.value = appStore.patient!.branch_uuid
  branchList.value = appStore.clinic!.line_sell_branch

  if (branchList.value.length <= 0) {
    // prevent branchList is null Error
    return
  }

  // set default value
  const isPatientMainBranchExist = branchList.value.find(
    branch => branch.uuid === patient.value?.branch_uuid,
  )
  if (isPatientMainBranchExist) {
    branchDisplay.value = isPatientMainBranchExist.text
    selectedBranch.value = isPatientMainBranchExist.uuid
  } else {
    if (branchList.value.length > 1) {
      branchDisplay.value = ''
      selectedBranch.value = ''
    } else {
      branchDisplay.value = branchList.value[0].text
      selectedBranch.value = branchList.value[0].uuid
    }
  }
})
</script>

<template>
  <template v-if="!course || !clinic">
    <LoadingDialog :show="true" />
  </template>

  <template v-else>
    <!-- Course List -->
    <div style="margin-top: 10px">
      <p style="text-align: center; font-weight: bold">
        {{ $t('course.buy_course.list') }}
      </p>
      <div style="background-color: white" class="box">
        <van-list v-if="course?.length">
          <div style="max-height: 250px; overflow-y: auto">
            <van-swipe-cell ref="swipeCard" v-for="item in course">
              <van-card
                style="background-color: white; margin: 0"
                :key="item.course_uuid"
                :title="item.name"
                currency=""
                :price="
                  item.is_deposit
                    ? useCurrencyDisplay(currency(item.deposit_cost))
                    : useCurrencyDisplay(currency(item.price))
                "
                :thumb="item.image_url"
              >
                <template v-if="item.is_deposit" #tags>
                  <van-tag type="warning">มัดจำ</van-tag>
                </template>
              </van-card>
              <template #left>
                <van-button
                  style="height: 100%"
                  :text="$t('delete')"
                  type="danger"
                  @click="removeItem(item.course_uuid)"
                />
              </template>
            </van-swipe-cell>
          </div>

          <van-divider
            style="color: black; margin-top: 10px; margin-bottom: 10px"
          />
          <van-row
            v-if="feeSum.value"
            justify="space-between"
            style="margin-left: 10px; margin-right: 10px; margin-bottom: 15px"
          >
            <van-col span="12">
              <span v-if="feeIncOrExc" style="font-size: small"
                >{{ $t('course.buy_course.fee') }} {{ feeIncOrExc }}</span
              >
            </van-col>
            <van-col style="text-align: right" span="12">
              <span>{{ useCurrencyDisplay(feeSum) }}</span>
            </van-col>
          </van-row>
          <van-row
            v-if="vatSum.value"
            justify="space-between"
            style="margin-left: 10px; margin-right: 10px; margin-bottom: 15px"
          >
            <van-col span="9">
              <span v-if="VATIncOrExc" style="font-size: small">
                VAT {{ VATIncOrExc }}</span
              >
            </van-col>
            <van-col style="text-align: right" span="15">
              <span>{{ useCurrencyDisplay(vatSum) }}</span>
            </van-col>
          </van-row>
          <van-row
            justify="space-between"
            style="margin-left: 10px; margin-right: 10px; margin-bottom: 15px"
          >
            <van-col
              style="text-decoration: underline; font-weight: bold"
              span="9"
            >
              <span>{{ $t('course.buy_course.total_price') }}</span>
            </van-col>
            <van-col style="text-align: right" span="15">
              <span>{{ useCurrencyDisplay(priceSum) }}</span>
            </van-col>
          </van-row>
        </van-list>

        <p v-else style="text-align: center; color: red; font-weight: bold">
          {{ $t('course.buy_course.no_buy_item') }}
        </p>
      </div>
    </div>

    <!-- Detail -->
    <van-form @submit="validator">
      <div style="margin-top: 10px">
        <p style="text-align: center; font-weight: bold">
          {{ $t('course.buy_course.detail') }}
        </p>
        <van-cell-group
          v-if="!patient && !clinic.new_patient_buy_course"
          class="box"
        >
          <van-cell
            :title="$t('course.buy_course.no_patient')"
            :value="$t('patient_create.please_contact_register')"
          />
        </van-cell-group>

        <van-cell-group
          v-else-if="!patient && clinic.new_patient_buy_course"
          class="box"
        >
          <van-cell
            is-link
            :title="$t('course.buy_course.no_patient')"
            :value="$t('patient_create.register')"
          />
        </van-cell-group>

        <van-cell-group v-else class="box">
          <van-cell
            :title="$t('patient.fullname')"
            :value="`${patient?.fname} ${patient?.lname}`"
          />
          <van-cell
            :title="$t('patient_create.mobile')"
            :value="patient?.mobile"
          />
          <van-cell
            :title="$t('course.buy_date')"
            :value="new Date().toDateString()"
          />
          <van-field
            v-model="paymentDisplays"
            is-link
            readonly
            required
            input-align="right"
            :label="$t('course.buy_course.payment_method')"
            :placeholder="$t('course.buy_course.payment_method_placeholder')"
            @click="paymentListToggle = true"
            :rules="[
              { required: true, message: $t('patient_create.error.required') },
            ]"
          />
          <van-popup v-model:show="paymentListToggle" round position="bottom">
            <van-picker
              :confirm-button-text="$t('submit')"
              :cancel-button-text="$t('cancel')"
              :title="$t('patient_create.branch')"
              :columns="displayPaymentList"
              @cancel="paymentListToggle = false"
              @confirm="onPaymentSelect"
            />
          </van-popup>
        </van-cell-group>
      </div>

      <!-- Clinic Detail -->
      <div style="margin-top: 10px; margin-bottom: 90px">
        <p style="text-align: center; font-weight: bold">
          {{ $t('course.buy_course.clinic_detail') }}
        </p>

        <van-cell-group class="box">
          <van-cell :title="$t('clinic')" :value="clinic.name" />
          <van-field
            v-model="branchDisplay"
            is-link
            readonly
            required
            input-align="right"
            :label="$t('course.buy_course.selected_branch')"
            :placeholder="$t('patient_create.branch_placeholder')"
            @click="branchListToggle = true"
            :rules="[
              { required: true, message: $t('patient_create.error.required') },
            ]"
          />
          <van-popup v-model:show="branchListToggle" round position="bottom">
            <van-picker
              :confirm-button-text="$t('submit')"
              :cancel-button-text="$t('cancel')"
              :title="$t('patient_create.branch')"
              :columns="dispalyBranchList"
              @cancel="branchListToggle = false"
              @confirm="onBranchSelect"
            />
          </van-popup>
        </van-cell-group>
      </div>

      <Footer
        :styles="{
          'padding-top': '10px',
          'padding-bottom': '10px',
          'background-color': 'white',
        }"
      >
        <van-row justify="center" align="center">
          <van-col span="7">
            <van-button type="danger" @click="openDialogOnClearCart">
              {{ $t('cancel') }}
            </van-button>
          </van-col>
          <van-col span="14">
            <van-button
              :disabled="patient && clinic && course.length > 0 ? false : true"
              block
              type="success"
              native-type="submit"
              :loading="loading"
              >{{ $t('course.buy_course.checkout') }}</van-button
            >
          </van-col>
        </van-row>
      </Footer>
    </van-form>

    <ConfirmDialog
      :show="confirmDialog"
      :title="confirmDialogTitle"
      :content="confirmDialogContent"
      :on-cancel="onCancel"
      :on-confirm="onConfirm"
    >
      <template v-if="confirmDialogType === 2" #button>
        <div style="width: 100%">
          <van-button
            style="margin-top: 0.5rem"
            type="danger"
            @click="() => onCancel()"
            >{{ cancelButtonText }}</van-button
          >
        </div>
        <div style="width: 100%">
          <van-button
            style="margin-top: 0.5rem"
            type="success"
            :loading="confirmButtonLoading"
            @click="() => onConfirm()"
            >{{ confirmButtonText }}</van-button
          >
        </div>
      </template>
    </ConfirmDialog>

    <CourseDialog
      :show="courseHaveBeenRemoveDialogToggle"
      :course-removed-list="courseHaveBeenRemoveDialogData"
      :course-change-list="courseHaveBeenEditDialogData"
      @on-confirm="updateCourseDataOnDialogClose"
    />
  </template>
</template>

<style scoped>
.box {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.button {
  margin-top: 10px;
}
</style>
