<script setup lang="ts">
import { showLoadingToast, closeToast } from 'vant'
import { ref, onMounted, watch } from 'vue'
import liff from '@line/liff'
import {
  getSubdomainFromHost,
  getParamsFromLiffUrl,
  getAPIUrlFromClinicType,
  validateRequiredParams,
  setUpCrmUrl,
} from '@/utils'
import { useAppStore } from '@/stores/AppStore'
import { getPatientLanguage, i18nInitialized } from '@/i18n'
import VConsole from 'vconsole'
import { LineLiffProfile } from './type/Line'
import 'vant/es/toast/style'

const appStore = useAppStore()
const ready = ref<boolean>(false)
const loading = ref<boolean>(true)

onMounted(async () => {
  // .::NOTE::.
  // ต้องเข้าด้วย
  // https://liff.line.me/<LIFF_ID>/<PATH>/?liffId=<LIFF_ID>&clinicType=<CLINIC_TYPE>&c=<CLINIC_UUID>
  // Params ที่ต้องใส่มากทุกครั้งเวลาเรียก liff คือ
  // 1. liffId
  // 2. clinicType
  // 3. clinicUUID
  loading.value = true
  const url_subdomain = getSubdomainFromHost()
  appStore.setCrmUrl(setUpCrmUrl(url_subdomain))
  if (
    ['uat', 'staging'].some(s => url_subdomain.includes(s)) ||
    !import.meta.env.PROD
  ) {
    new VConsole({ theme: 'dark' })
  }
  try {
    const params = getParamsFromLiffUrl(window.location.search)
    validateRequiredParams(params)
    await liff.init({ liffId: params.get('liffId') || '' })
    // ถ้าเจอ error LiffId is not found. ก็คือ API getProfile()
    if (!liff.isLoggedIn()) {
      liff.login({ redirectUri: window.location.href })
    } else {
      // clinic data
      const clinic_uuid = params.get('c') as string
      appStore.setUrl(
        getAPIUrlFromClinicType(params.get('clinicType') as string),
      )
      appStore.setClinicUUID(clinic_uuid)

      // user data
      appStore.setUserProfile((await liff.getProfile()) as LineLiffProfile)
      appStore.setLanguage(await getPatientLanguage())
      await i18nInitialized({ ns: clinic_uuid })
      ready.value = true
    }
  } catch (e) {
    console.error(`LIFF init failed. ${e}`)
    if (import.meta.env.PROD) {
      window.location.href = 'https://jeracloud.com'
    }
  } finally {
    loading.value = false
  }
})

watch(
  loading,
  loading => {
    if (loading) {
      showLoadingToast({
        // message: t('loading'),
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner',
        overlay: true,
      })
    } else {
      closeToast()
    }
  },
  { immediate: true },
)
</script>

<template>
  <!-- <van-overlay :show="loading">
    <div class="wrapper">
      <div class="block">
        <h2>{{ $t('loading') }}</h2>
      </div>
    </div>
  </van-overlay> -->
  <van-overlay :show="!ready && !loading">
    <div class="wrapper">
      <div class="block">
        <h2>{{ $t('something_went_wrong') }}</h2>
      </div>
    </div>
  </van-overlay>
  <router-view v-if="ready" v-slot="{ Component }">
    <component :is="Component" :key="$route.path"></component>
  </router-view>
</template>

<style lang="css">
html,
body {
  font-family: 'K2D', sans-serif;
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  border-radius: 25px;
  width: 300px;
  height: 300px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
