<script setup lang="ts">
import { useVoucherRepository } from '@/repositories/voucherRepo'
import { getParamsFromLiffUrl } from '@/utils';
import liff from '@line/liff'
import { Ref, onMounted, ref } from 'vue'

const loading: Ref<boolean> = ref(true)
const condition_text: Ref<string> = ref('')

const closeLiff = () => {
  liff.closeWindow()
}

onMounted(async () => {
  const params = getParamsFromLiffUrl(window.location.href)
  const clinic_uuid = params.get('c') || ''
  const voucher_uuid = params.get('u') || ''

  if (!clinic_uuid || !voucher_uuid) {
    console.error('undefine query string')
    return
  }

  const repository = useVoucherRepository(clinic_uuid)
  condition_text.value = await repository.fetchVoucherCondition(voucher_uuid)
  loading.value = false
})
</script>

<template>
  <van-popup
    style="text-align: center; padding: 64px; border-radius: 20px"
    :show="loading"
  >
    <van-loading color="green" text-color="black" size="48px" vertical>
      Loading...
    </van-loading>
  </van-popup>

  <van-dialog
    style="text-align: center"
    :show="!loading && condition_text != ''"
    :title="$t('voucher.condition')"
    confirmButtonText="confirm"
    @confirm="closeLiff"
  >
    <div style="max-height: 500px; overflow-y: scroll">
      <p v-html="condition_text"></p>
    </div>
  </van-dialog>
</template>
