<script setup lang="ts">
import { useCourseRepository } from '@/repositories/courseRepo'
import {
  Course,
  PatientcourseitemSet,
  RenderPatientcourseitemSet,
} from '@/type/Course'
import { computed, onMounted, ComputedRef, ref, Ref } from 'vue'
import { format } from 'date-fns'
import { t } from 'i18next'
import { getParamsFromLiffUrl } from '@/utils'
import CourseCollapseItem from '@/components/CourseDetail/CourseCollapseItem.vue'

// API repo
// data
const course: Ref<Course | null> = ref(null)

// course item list
const all_item_list: Ref<RenderPatientcourseitemSet[] | []> = ref([]) // ใช้แสดงเพื่อแสดงรายการทั้งหมดของ คอร์สตามใจฉัน และ คอร์สบุฟฟเฟต์ เท่านั้น
const service_item_list: Ref<RenderPatientcourseitemSet[] | []> = ref([]) // ใช้แสดง ประวัติ รวมทั้ง รายการของที่ใช้ แล้วของบริการ (ในกรณีของ คอร์สตามใจฉันจะรวมเอายาและบริการมาแสดงในนี้ทั้งหมด)
const medicine_item_list: Ref<RenderPatientcourseitemSet[] | []> = ref([]) // ใช้แสดงประวัติ ประวัติ รวมทั้ง รายการของที่ใช้ แล้วของยา (ในกรณีของ คอร์สตามใจฉันจะเป็น empty array)

// total
const total_service_item_amount: Ref<number> = ref(0) // use for counting total of course service item
const total_medicine_item_amount: Ref<number> = ref(0) // use for counting total of course service item

// remaining
const remaining_service_item_amount: Ref<number> = ref(0) // use for counting total of course service item left
const remaining_medicine_item_amount: Ref<number> = ref(0) // use for counting total of course service item left

// ui toggle
const freedom_course: Ref<boolean> = ref(false)

// computed
// แสดงผลจำนวนวันคงเหลือสำหรับใช้งาน course
const course_remaining_days = computed(() => {
  if (course.value?.start_date) {
    // เลยวัน*เริ่ม*ของคอร์สมาแล้ว แปลว่าสามารถใช้งานคอร์สได้แล้ว
    if (!course.value.end_date) {
      return t('course.no_expire')
    }

    let now_distance =
      new Date(course.value.end_date).valueOf() - new Date().valueOf()
    let days_remaining = Math.ceil(now_distance / (1000 * 60 * 60 * 24))

    return `${days_remaining} ${t('date')}`
  } else {
    // start date ยังไม่มีจะแสดงตามนี้
    if (course.value?.expire_after_use_flag) {
      return `${course.value.duration} ${t('course.duration')}`
    }

    return '-'
  }
})

const status: ComputedRef<'active' | 'standby' | 'expired'> = computed(() => {
  // แสดงผลสถานะของ course โดยคิดเอาจาก start_date, end_date, วันปัจจุบัน
  // return 'standby' | 'active' | 'expired'
  if (!course.value) {
    // if no course data
    return 'standby'
  }

  // if (!course.value.start_date || new Date() < new Date(course.value.start_date)) {
  if (
    !course.value.start_date ||
    new Date() < new Date(course.value.start_date)
  ) {
    // ตราบใดที่ยังไม่ถึงวันเริ่ม course จะถือว่ายังเป็น 'standby' อยู่
    return 'standby'
  }

  if (course.value.end_date && new Date() > new Date(course.value.end_date)) {
    // ถ้าเลยวันหมดอายุใช้งานมาแล้ว จะถือว่าเป็น 'expired' ทุกกรณี
    return 'expired'
  }

  return 'active'
})

const remaining_percent: ComputedRef<number> = computed(() => {
  if (!course.value || !course.value.estimate_price) {
    return 100
  }

  const estimate_price = parseFloat(course.value.estimate_price)
  if (estimate_price === 0) return 0

  return (course.value.remaining_price / estimate_price) * 100
})

// method
// ทำหน้าที่แยก course_item ออกเป็น list ให้เหมาะกับคอร์สแต่ละประเภท
function set_service_and_medicine(
  course_item_array: PatientcourseitemSet[],
  type: string,
) {
  // หากไม่มี course_item เลยก็จบการทำงานทันที
  if (course_item_array === null || course_item_array.length === 0) {
    // this.no_service = true
    // this.no_medicine = true
    service_item_list.value = []
    medicine_item_list.value = []
  }

  // รวม course_item ที่ซ้ำๆ เป็นตัวเดียวกัน
  // แนวคิดคือ หากเป็นตัวที่มีใน exsting แล้วให้นับ counter เพิ่ม ถ้ายังไม่มีให้ push เข้าไปใหม่ทั้งก้อน
  // หลักการที่ใช้คือ สร้าง key ตัวหนึ่งที่จะเป็นตัวบอกความแตกต่าง โดยรวมเอาจาก attribute ทั้งหมดทีเป็นตัวตัดสินความต่าง
  // กรณีคอร์สตามใจเมื่อไหร่ ทุกอย่างจะถูกนับในตัวแปรที่เกี่ยวกับ service เพียงตัวเดียว
  let existing: any[] = []
  course_item_array.forEach(item => {
    let key =
      String(item.service) +
      String(item.medicine) +
      String(item.used) +
      String(item.used_date) +
      String(item.amount) // key ที่ใช่แยกความแตกต่าง
    let amount = parseFloat(item.amount)

    switch (amount) {
      // amount เป็น 0 ไม่ได้สนใจ
      case 0:
        break
      default:
        // หาว่าใน existing ยังมีอยู่ไหม
        if (existing.find(({ temp_key }) => temp_key === key) == undefined) {
          // ถ้าไม่มีใส่ตัวใหม่เพิ่ม
          existing.push({
            ...item,
            temp_key: key,
            counter: amount,
          })
        } else {
          // ถ้ามีนับเพิ่ม
          let index = existing.findIndex(({ temp_key }) => temp_key === key)
          existing[index].counter += amount
        }

        if (item.service === null && item.medicine === null) {
          // กรณีคอร์สตามใจฉัน
          freedom_course.value = true // toggle ว่าเป็นคอร์สตามใจฉัน
          total_service_item_amount.value = 0 // clear service_amount ที่เคยนับ เดี๋ยวไปนับใหม่ข้างล่างแทน
          remaining_service_item_amount.value = 0 // clear medicine_remaining_amount ที่เคยนับ เดี๋ยวไปนับใหม่ข้างล่างแทน
        } else if (item.service != null && !freedom_course.value) {
          // ตัวนับ service
          total_service_item_amount.value += amount // นับ counter ของตัว service (total)
          if (item.used === false) {
            remaining_service_item_amount.value += amount // นับ counter ของตัว service (ตัวที่ยังไม่ได้ใช้)
          }
        } else if (item.medicine !== null && !freedom_course.value) {
          total_medicine_item_amount.value += amount // นับ counter ของตัว medicine (ทั้งหมดที่มี)
          if (item.used == false) {
            remaining_medicine_item_amount.value += amount // นับ counter ของตัว medicine (ตัวที่ยังไม่ได้ใช้)
          }
        }

        break
    }
  })

  let service_remaining, medicine_remaining
  if (freedom_course.value) {
    // กรณีที่เป็นคอร์สตามใจฉันจะไม่แยกว่าเป็น ยา หรือ บริการ (เพราะแยกไม่ได้ ไม่รู้จะดูจากตัวอะไรดี)
    service_remaining = existing
    medicine_remaining = []

    // มานับ amount ที่นี่แทน
    service_remaining.forEach(item => {
      let amount = item.counter
      total_service_item_amount.value += amount
      if (item.used === false) {
        remaining_service_item_amount.value += amount // นับ counter ของตัว service (ตัวที่ยังไม่ได้ใช้)
      }
    })
  } else if (type === '03') {
    // กรณีคอร์สบุฟเฟต์ต้องกรองอันที่ยังไม่ได้ใช้ออกด้วย
    all_item_list.value = existing.filter(item => item.used === false)
    service_remaining = existing.filter(
      item => item.service !== null && item.used === true,
    ) // seperate service from all course item
    medicine_remaining = existing.filter(
      item => item.medicine !== null && item.used === true,
    ) // seperate medicine from all course item
  } else {
    service_remaining = existing.filter(item => item.service !== null) // seperate service from all course item
    medicine_remaining = existing.filter(item => item.medicine !== null) // seperate medicine from all course item
  }

  // ใส่ค่าลงตัวแปร amount ของฝั่ง service
  if (service_remaining.length === 0) {
    // this.no_service = true
    service_item_list.value = []
  } else {
    // sort by พร้อมใช้งาน
    service_item_list.value = service_remaining.sort((a, b) => {
      if (a.used === false && b.used === true) {
        return -1
      }
      return 1
    })
    // this.no_service = false
  }

  // ใส่ค่าลงตัวแปร amount ของฝั่ง medicine
  if (medicine_remaining.length === 0) {
    // this.no_medicine = true
    medicine_item_list.value = []
  } else {
    // sort by พร้อมใช้งาน
    medicine_item_list.value = medicine_remaining.sort((a, b) => {
      if (a.used === false && b.used === true) {
        return -1
      }

      return 1
    })
    // this.no_medicine = false
  }

  return 0
}

// mount
onMounted(async () => {
  const params = getParamsFromLiffUrl(window.location.href)
  const clinic_uuid = params.get('c') || ''
  const course_uuid = params.get('u') || ''

  const repository = useCourseRepository(clinic_uuid)
  course.value = await repository.fetchCourse(course_uuid)
  set_service_and_medicine(
    course.value!.patientcourseitem_set,
    course.value!.type,
  )
})
</script>

<template>
  <!-- loading -->
  <div v-if="!course">
    <van-popup
      style="text-align: center; padding: 64px; border-radius: 20px"
      :show="true"
    >
      <van-loading color="green" text-color="black" size="48px" vertical>
        Loading...
      </van-loading>
    </van-popup>
  </div>

  <div v-else>
    <van-divider>{{ $t('course.title') }}</van-divider>
    <van-cell-group inset>
      <van-cell :title="$t('course.name')" :value="course.course_name ?? ''" />
      <van-cell
        :title="$t('course.branch')"
        :value="course.branch_name ?? ''"
      />
      <van-cell
        :title="$t('course.detail')"
        :value="course.course_desc ?? '-'"
      />
      <van-cell :title="$t('course.price')" :value="course.disc_price ?? ''" />

      <!-- คอร์สจัดฟัน only -->
      <div v-if="course.type === '05'">
        <van-cell
          :title="$t('course.extimate_price')"
          :value="course.estimate_price"
        />
        <van-progress
          :pivot-text="course.remaining_price + ''"
          color="#07B53B"
          :percentage="remaining_percent"
        />
      </div>

      <!-- course type -->
      <van-cell
        v-if="course.type === '01' && freedom_course"
        :title="$t('course.course_type')"
        :value="$t('course.freedom_course')"
      />
      <van-cell
        v-else-if="course.type === '01'"
        :title="$t('course.course_type')"
        :value="$t('course.normal_course')"
      />
      <van-cell
        v-if="course.type === '03'"
        :title="$t('course.course_type')"
        :value="$t('course.buffet')"
      />
      <van-cell
        v-if="course.type === '04'"
        :title="$t('course.course_type')"
        :value="$t('course.free')"
      />
      <van-cell
        v-if="course.type === '05'"
        :title="$t('course.course_type')"
        :value="$t('course.estimate')"
      />
      <van-cell
        v-if="course.paid"
        :title="$t('course.buy_date')"
        :value="format(new Date(course.buy_date), 'dd/MM/yyyy')"
      />

      <!-- status -->
      <van-cell :title="$t('course.status')">
        <van-tag v-if="status === 'active'" type="success">{{
          $t('enable')
        }}</van-tag>
        <van-tag v-if="status === 'standby'" color="#fcad03">{{
          $t('wait_to_enable')
        }}</van-tag>
        <van-tag v-if="status === 'expired'" type="danger">{{
          $t('expire')
        }}</van-tag>
      </van-cell>

      <van-cell
        :title="$t('course.start_date')"
        :value="
          course.start_date
            ? format(new Date(course.start_date), 'dd/MM/yyyy')
            : $t('course.not_open')
        "
      />
      <van-cell
        :title="$t('course.end_date')"
        :value="
          course.end_date
            ? format(new Date(course.end_date), 'dd/MM/yyyy')
            : !course.start_date
            ? '-'
            : $t('course.no_expire')
        "
      />
      <van-cell
        v-if="course.end_date != $t('course.no_expire')"
        :title="$t('course.time_remain')"
        :value="course_remaining_days"
      />

      <!-- ส่วนรายการทัี่สามารถใช้ได้ *เฉพาะ* คอร์สบุฟเฟต์ -->
      <course-collapse-item
        v-if="course.type === '03'"
        course_type="03"
        :itemList="all_item_list"
        :collapse_title="$t('course.can_use_list')"
        :show-price="false"
        :only_cell="all_item_list.every(item => !item.show_receipt_flag)"
        :showUsedDate="true"
        :show-medicine-tag="true"
        :show-service-tag="true"
        :showUsedTag="true"
      />

      <!-- ส่วนรายการบริการ -->
      <van-cell
        v-if="
          service_item_list.length === 0 && ['03', '05'].includes(course.type)
        "
        :title="$t('course.service_history')"
        :value="$t('course.no_service_history')"
      />
      <van-cell
        v-else-if="service_item_list.length === 0"
        :title="$t('course.service_remain')"
        :value="$t('course.no_service_remain')"
      />
      <div v-else>
        <!-- ส่วนรายการบริการ คอร์สตามใจฉัน -->
        <course-collapse-item
          v-if="freedom_course"
          course_type="01"
          :itemList="service_item_list"
          :collapse_title="`${$t(
            'course.all_remain',
          )} [${remaining_service_item_amount}/${total_service_item_amount}]`"
          :show-price="false"
          :only_cell="service_item_list.every(item => !item.show_receipt_flag)"
          :showUsedDate="true"
          :show-medicine-tag="false"
          :show-service-tag="false"
          :showUsedTag="true"
        />

        <!-- ส่วนรายการบริการ คอร์สบุฟเฟต์ -->
        <course-collapse-item
          v-else-if="course.type === '03'"
          course_type="03"
          :itemList="service_item_list"
          :collapse_title="$t('course.service_history')"
          :show-price="false"
          :only_cell="service_item_list.every(item => !item.show_receipt_flag)"
          :showUsedDate="true"
          :show-medicine-tag="false"
          :show-service-tag="false"
          :showUsedTag="true"
        />

        <!-- ส่วนรายการบริการ คอร์สจัดฟัน -->
        <course-collapse-item
          v-else-if="course.type === '05'"
          course_type="05"
          :itemList="service_item_list"
          :collapse_title="$t('course.service_history')"
          :show-price="true"
          :only_cell="service_item_list.every(item => !item.show_receipt_flag)"
          :showUsedDate="true"
          :show-medicine-tag="false"
          :show-service-tag="false"
          :showUsedTag="true"
        />

        <!-- ส่วนรายการบริการ คอร์สธรรมดา -->
        <course-collapse-item
          v-else
          course_type="01"
          :itemList="service_item_list"
          :collapse_title="`${$t(
            'course.service_remain',
          )} [${remaining_service_item_amount}/${total_service_item_amount}]`"
          :only_cell="service_item_list.every(item => !item.show_receipt_flag)"
          :showPrice="false"
          :showUsedDate="true"
          :showMedicineTag="false"
          :showServiceTag="false"
          :showUsedTag="true"
        />
      </div>

      <!-- ส่วนรายการยา -->
      <!-- ส่วนรายการ คอร์สตามใจฉัน จะไม่มีแสดงรายการยา -->
      <div v-if="freedom_course"></div>
      <van-cell
        v-else-if="medicine_item_list.length === 0 && !freedom_course"
        :title="$t('course.medicine_list')"
        :value="$t('course.medicine_none')"
      />
      <div v-else>
        <!-- ส่วนรายการยา คอร์สบุฟเฟต์ -->
        <course-collapse-item
          v-if="course.type === '03'"
          course_type="03"
          :itemList="medicine_item_list"
          :collapse_title="$t('course.medicine_history')"
          :show-price="false"
          :only_cell="medicine_item_list.every(item => !item.show_receipt_flag)"
          :showUsedDate="true"
          :show-medicine-tag="false"
          :show-service-tag="false"
          :showUsedTag="true"
        />

        <!-- ส่วนรายการยา คอร์สแจกของ-->
        <course-collapse-item
          v-else-if="course.type === '04'"
          course_type="04"
          :itemList="medicine_item_list"
          :collapse_title="`${$t(
            'course.item_remain_list',
          )} [${remaining_medicine_item_amount}/${total_medicine_item_amount}]`"
          :show-price="false"
          :only_cell="medicine_item_list.every(item => !item.show_receipt_flag)"
          :showUsedDate="true"
          :show-medicine-tag="false"
          :show-service-tag="false"
          :showUsedTag="true"
        />

        <!-- ส่วนรายการยา คอร์สจัดฟัน-->
        <course-collapse-item
          v-else-if="course.type === '05'"
          course_type="05"
          :itemList="medicine_item_list"
          :collapse_title="$t('course.medicine_list')"
          :show-price="true"
          :only_cell="medicine_item_list.every(item => !item.show_receipt_flag)"
          :showUsedDate="true"
          :show-medicine-tag="false"
          :show-service-tag="false"
          :showUsedTag="true"
        />

        <!-- ส่วนรายการยา คอร์สธรรมดา-->
        <course-collapse-item
          v-else
          course_type="01"
          :itemList="medicine_item_list"
          :collapse_title="`${$t(
            'course.medicine_remain',
          )} [${remaining_medicine_item_amount}/${total_medicine_item_amount}]`"
          :only_cell="medicine_item_list.every(item => !item.show_receipt_flag)"
          :showPrice="false"
          :showUsedDate="true"
          :show-medicine-tag="false"
          :show-service-tag="false"
          :showUsedTag="true"
        />
      </div>
    </van-cell-group>
  </div>
</template>

<style scoped>
.van-card__content {
  min-height: 4rem;
}

.van-progress {
  margin: 1rem;
}

.van-progress__pivot {
  font-size: 15px;
}

.active {
  color: green;
}
</style>
