// import { Message } from '@line/bot-sdk'
import { Action, FlexBox, FlexBubble, FlexButton } from '@line/bot-sdk'
import { Promotion } from '../types/promotionsType'

function isValidUrl(urlString: string): boolean {
  let url
  try {
    url = new URL(urlString)
  } catch (e) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

function getButtonUrl(button_text: string, button_url: string): Action {
  if (isValidUrl(button_url)) {
    return {
      type: 'uri',
      label: button_text,
      uri: encodeURI(button_url),
    }
  }
  return {
    type: 'postback',
    label: button_text,
    data: `action=promotion&id=${button_url}`,
    displayText: button_url,
  }
}

function getPromotionCard(promotion: Promotion): FlexBubble {
  switch (promotion.line_style.style) {
    case '01':
      return getType_01_Style(promotion)
    case '02':
      return getType_02_Style(promotion)
    case '03':
      return getType_03_Style(promotion)
    default:
      return {
        type: 'bubble',
        body: {
          type: 'box',
          layout: 'vertical',
          contents: [],
        },
      }
  }
}

function getBanner(promotion: Promotion): FlexBox {
  if (!promotion.banner_text) {
    // if don't have banner text return null
    return {
      type: 'box',
      layout: 'vertical',
      contents: [],
    }
  }

  return {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'text',
        text: promotion.banner_text,
        color: promotion.line_style.banner_color,
        align: 'center',
        size: 'xs',
      },
    ],
    position: 'absolute',
    cornerRadius: '20px',
    offsetTop: '18px',
    backgroundColor: promotion.line_style.banner_background_color,
    offsetStart: '18px',
    paddingAll: '7px',
    justifyContent: 'center',
    alignItems: 'center',
  }
}

function getType_01_Style(promotion: Promotion): FlexBubble {
  return {
    type: 'bubble',
    body: {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'image',
          url: promotion.image_url,
          size: 'full',
          aspectMode: 'cover',
          aspectRatio: '2:3.2',
        },
        {
          type: 'box',
          layout: 'vertical',
          contents: [
            {
              type: 'box',
              layout: 'vertical',
              contents: [
                {
                  type: 'filler',
                },
                {
                  type: 'box',
                  layout: 'baseline',
                  contents: [
                    {
                      type: 'text',
                      text: promotion.button_text,
                      color: promotion.line_style.button_color,
                      weight: promotion.line_style.button_bold
                        ? 'bold'
                        : 'regular',
                      align: 'center',
                    },
                  ],
                  spacing: 'sm',
                  margin: 'none',
                  action: getButtonUrl(
                    promotion.button_text,
                    promotion.button_url,
                  ),
                },
                {
                  type: 'filler',
                },
              ],
              borderWidth: '2px',
              cornerRadius: '20px',
              spacing: 'sm',
              borderColor: promotion.line_style.button_border_color,
              margin: 'xxl',
              height: '45px',
              backgroundColor: promotion.line_style.button_background_color,
            },
          ],
          spacing: 'none',
          margin: 'none',
          position: 'absolute',
          offsetBottom: '0px',
          offsetStart: '0px',
          offsetEnd: '0px',
          paddingAll: '20px',
          paddingTop: '18px',
        },
        getBanner(promotion),
      ],
      paddingAll: '0px',
    },
  }
}

function getType_02_Style(promotion: Promotion): FlexBubble {
  return {
    type: 'bubble',
    body: {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'image',
          url: promotion.image_url,
          size: 'full',
          aspectMode: 'cover',
          gravity: 'top',
          aspectRatio: '2:3.2',
        },
        getBanner(promotion),
        {
          type: 'box',
          layout: 'vertical',
          contents: [
            {
              type: 'box',
              layout: 'vertical',
              contents: [
                {
                  type: 'text',
                  text: promotion.title,
                  size: 'xl',
                  color: promotion.line_style.title_color,
                  weight: promotion.line_style.title_bold ? 'bold' : 'regular',
                  wrap: true,
                },
              ],
            },
            {
              type: 'box',
              layout: 'vertical',
              contents: [
                {
                  type: 'text',
                  text: promotion.subtitle,
                  size: 'md',
                  color: promotion.line_style.subtitle_color,
                  weight: promotion.line_style.subtitle_bold
                    ? 'bold'
                    : 'regular',
                  wrap: true,
                },
              ],
            },
            {
              type: 'box',
              layout: 'baseline',
              contents: promotion.note
                ? [
                    {
                      type: 'text',
                      text: promotion.note,
                      color: promotion.line_style.note_color,
                      size: 'xs',
                      flex: 1,
                      wrap: true,
                      weight: promotion.line_style.note_bold
                        ? 'bold'
                        : 'regular',
                    },
                  ]
                : [],
              spacing: 'lg',
              paddingTop: '10px',
            },
            {
              type: 'box',
              layout: 'vertical',
              contents: [
                {
                  type: 'text',
                  text: promotion.button_text,
                  color: promotion.line_style.button_color,
                  flex: 0,
                  offsetTop: '-2px',
                  align: 'center',
                  gravity: 'center',
                },
              ],
              borderWidth: '1px',
              cornerRadius: '4px',
              spacing: 'none',
              backgroundColor: promotion.line_style.button_background_color,
              margin: 'xl',
              height: '40px',
              alignItems: 'center',
              justifyContent: 'center',
              borderColor: promotion.line_style.button_border_color,
              action: getButtonUrl(promotion.button_text, promotion.button_url),
            },
          ],
          position: 'absolute',
          offsetBottom: '0px',
          offsetStart: '0px',
          offsetEnd: '0px',
          backgroundColor: promotion.line_style.promotion_card_background,
          paddingAll: '20px',
          paddingTop: '18px',
        },
      ],
      paddingAll: '0px',
    },
  }
}

function getType_03_Style(promotion: Promotion): FlexBubble {
  return {
    type: 'bubble',
    hero: {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'image',
          url: promotion.image_url,
          size: 'full',
          aspectRatio: '20:13',
          aspectMode: 'fit',
        },
        getBanner(promotion),
      ],
    },
    body: {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'text',
          text: promotion.title,
          weight: promotion.line_style.title_bold ? 'bold' : 'regular',
          color: promotion.line_style.title_color,
          size: 'xl',
          margin: 'none',
          wrap: true,
        },
        {
          type: 'text',
          text: promotion.subtitle,
          margin: 'sm',
          weight: promotion.line_style.subtitle_bold ? 'bold' : 'regular',
          color: promotion.line_style.subtitle_color,
          size: 'md',
          wrap: true,
        },
        {
          type: 'box',
          layout: 'vertical',
          contents: promotion.note
            ? [
                {
                  type: 'text',
                  text: promotion.note ?? ' ',
                  weight: promotion.line_style.note_bold ? 'bold' : 'regular',
                  color: promotion.line_style.note_color,
                  size: 'xs',
                  wrap: true,
                  maxLines: 6,
                },
              ]
            : [],
          spacing: 'none',
          margin: 'sm',
          height: '100px',
        },
        {
          type: 'box',
          layout: 'vertical',
          contents: [
            {
              type: 'text',
              text: promotion.button_text,
              color: promotion.line_style.button_color,
              gravity: 'center',
              align: 'center',
            },
          ],
          position: 'absolute',
          offsetBottom: '19px',
          offsetStart: '18px',
          offsetEnd: '18px',
          justifyContent: 'center',
          alignItems: 'center',
          cornerRadius: '15px',
          backgroundColor: promotion.line_style.button_background_color,
          borderColor: promotion.line_style.button_border_color,
          borderWidth: '2px',
          paddingAll: '12px',
          action: getButtonUrl(promotion.button_text, promotion.button_url),
        },
      ],
      backgroundColor: promotion.line_style.promotion_card_background,
    },
  }
}

function getTag(tag: string, style: FlexButton['style']): FlexBox {
  if (!tag) {
    // if tag = ''
    // return empty space
    return {
      type: 'box',
      layout: 'vertical',
      contents: [],
      cornerRadius: '9px',
    }
  }

  return {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'button',
        action: {
          type: 'message',
          label: tag,
          text: `Promotions: ${tag}`,
        },
        style: style,
      },
    ],
    cornerRadius: '9px',
  }
}

export const promotionTemplate = {
  getPromotionCard,
  getBanner,
  getTag,
}
