<script setup lang="ts">
import {
  useCourseRepository,
  formatSellCourseWithQuota,
} from '@/repositories/courseRepo'

import { useAppStore } from '@/stores/AppStore'
import { CourseLineSellFormated } from '@/type/Course'
import { Ref, computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

// components
import LoadingDialog from '@components/LoadingDialog.vue'

const appStore = useAppStore()
const router = useRouter()
const repository = useCourseRepository(appStore.clinic?.uuid!)

const search = ref('')
const course = ref<CourseLineSellFormated[]>([])
const courseLoading = ref<boolean>(false)
const view: Ref<'list' | 'table'> = ref('list')

const patient_name = computed(() => {
  if (appStore.language === 'th') {
    return appStore.patient?.fname
  }

  return appStore.patient?.fname_en
    ? appStore.patient?.fname_en
    : appStore.patient?.fname
})

const filtered_course = computed(() => {
  if (search.value) {
    return course.value.filter(course => {
      if (
        course.name.toLowerCase().includes(search.value.toLowerCase()) ||
        course.liff_data.cost_on_LINE.toString().includes(search.value)
      ) {
        return course
      }
    })
  }

  return course.value
})

const onClickCourse = (course_uuid: string) => {
  router.push({
    name: 'CourseSellDetail',
    params: { course_uuid: course_uuid },
  })
}

const onCheckout = () => {
  router.replace({ name: 'CourseCheckout' })
}

onMounted(async () => {
  if (!appStore.sell_course?.length) {
    courseLoading.value = true
  } else {
    course.value = appStore.sell_course
  }

  const { sell_course, patient_course_quota } =
    await repository.fetchSellCourseList()

  // ถ้ามี uuid ของ patient = เป็น patient แล้ว มี quota แล้ว ให้ filter quota
  // ถ้ายังไม่มี ให้ถือว่า buy_count = 0
  course.value = formatSellCourseWithQuota(sell_course, patient_course_quota)
  appStore.setGlobalSellCourse(course.value)
  courseLoading.value = false
})
</script>

<template>
  <LoadingDialog v-if="!appStore.clinic" :show="true" />

  <div v-else>
    <top-nav-bar :title="$t('clinic')">
      <template #left>
        <van-badge
          :content="appStore.cart.length > 0 ? appStore.cart.length : undefined"
        >
          <van-icon @click="onCheckout" name="shopping-cart-o" size="25" />
        </van-badge>
      </template>

      <template #title>
        <span class="clinic_branch text-center">{{ $t('clinic') }}</span>
        <p class="margin-y" style="--margin: 0px">
          {{ appStore.getClinicTname() }}
        </p>
        <!-- <van-row align="center" class="text-center">
          </van-row> -->
      </template>
    </top-nav-bar>

    <div>
      <van-row align="center" justify="space-around" class="search text-center">
        <van-col
          style="width: 100%; overflow: hidden; text-overflow: ellipsis"
          span="5"
          ><span class="text-bold">
            {{ $t('patient.profile_name', { name: patient_name }) }}</span
          ></van-col
        >

        <van-col span="4">
          <van-icon name="search" size="30" color="black" />
        </van-col>

        <van-col span="15">
          <van-field
            v-model="search"
            name="search"
            :placeholder="$t('course.search_course')"
          />
        </van-col>
      </van-row>

      <van-row align="center" class="list-header text-center">
        <van-col span="16">
          <h3 class="margin-y" style="--margin: 0px">
            {{ $t('course.buy_course.course_list') }}
          </h3>
        </van-col>

        <van-col span="4"
          ><van-icon
            :color="view === 'list' ? 'red' : 'black'"
            name="bars"
            size="20"
            @click="view = 'list'"
        /></van-col>
        <van-col span="4"
          ><van-icon
            :color="view === 'table' ? 'red' : 'black'"
            name="apps-o"
            size="20"
            @click="view = 'table'"
        /></van-col>
      </van-row>
    </div>

    <div v-if="courseLoading">
      <van-loading style="margin-top: 20px" size="30px" color="#0094ff" vertical
        >Loading...</van-loading
      >
    </div>

    <div v-else-if="course.length <= 0">
      <h3 class="text-center" style="color: #999999">
        {{ $t('course.buy_course.no_course_list') }}
      </h3>
    </div>

    <div
      v-else-if="view === 'list'"
      style="height: calc(100vh - 174px); overflow: auto"
      :class="view === 'list' ? 'active' : 'inactive'"
    >
      <van-list v-for="item in filtered_course">
        <van-card
          @click="onClickCourse(item.uuid + '')"
          currency="฿"
          :price="item.liff_data.cost_on_LINE"
          :title="item.name"
          :desc="item.description || ''"
          :thumb="item.liff_data.image_url"
        />
        <van-divider style="margin-top: 5px; margin-bottom: 5px"></van-divider>
      </van-list>
    </div>

    <div
      v-else-if="view === 'table'"
      style="height: calc(100vh - 174px); overflow: auto"
    >
      <van-grid clickable :column-num="2" :gutter="20">
        <van-grid-item
          @click="onClickCourse(item.uuid + '')"
          v-for="item in filtered_course"
        >
          <van-image :src="item.liff_data.image_url" height="150" fit="cover" />
          <span style="margin-top: 10px" class="text-center">{{
            item.name
          }}</span>
          <span style="color: #777777">฿{{ item.liff_data.cost_on_LINE }}</span>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<style scoped>
.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.search {
  padding: 15px;
}

.list-header {
  padding: 15px;
}

.margin-y {
  margin-top: var(--margin);
  margin-bottom: var(--margin);
}

.clinic_branch {
  font-weight: bold;
}

.active {
  color: green;
}

.inactive {
  color: #555555;
}
</style>
