<script setup lang="ts">
import { PatientCard, PointLot } from '@/type/MemberCard'
import { Ref, onMounted, ref } from 'vue'
import { useMemberCardRepository } from '@/repositories/memberRepo'
import { getParamsFromLiffUrl } from '@/utils'
import { format } from 'date-fns'
import { computed } from 'vue'
import currency from 'currency.js'
import { t } from 'i18next'

const member_card: Ref<PatientCard | null> = ref(null)

const expire_date = computed(() => {
  if (member_card.value && member_card.value.expire_date) {
    return format(new Date(member_card.value.expire_date), 'dd MMM yyyy')
  }

  return ''
})

const expireDatePointLot = (expireDate: string | Date) => {
  if (expireDate) {
    return format(new Date(expireDate), 'dd MMM yyyy')
  }

  return t('membercard.no_expire')
}

const cash_rate = computed(() => {
  if (member_card.value && member_card.value.member_card.cash_rate) {
    return currency(member_card.value.member_card.cash_rate, { precision: 2 })
      .value
  }

  return ''
})

const point_rate = computed(() => {
  if (member_card.value && member_card.value.member_card.point_rate) {
    return currency(member_card.value.member_card.point_rate, { precision: 2 })
      .value
  }

  return ''
})

const findNearestDate = (pointLot: PointLot[]) => {
  const today = new Date()
  let smallestDateDifferent = Infinity
  let nearestDate = null

  pointLot.forEach(item => {
    if (item.expire_date) {
      const end_date = new Date(item.expire_date)
      const dateDifference = Math.abs(today.getTime() - end_date.getTime())

      if (dateDifference < smallestDateDifferent) {
        nearestDate = end_date
        smallestDateDifferent = dateDifference
      }
    }
  })

  return nearestDate
}

const sumNearestPointLot = (pointLot: PointLot[], date: Date | null) => {
  if (!date) {
    // sum all
    const sum = pointLot.reduce((totolPoint: currency, currentValue) => {
      return totolPoint.add(currentValue.point)
  }, currency(0))

    return sum.value
  }

  // sum only neareast
  const sum = pointLot.reduce((totolPoint: currency, currentValue) => {
    if (new Date(currentValue.expire_date).getTime() === date.getTime()) {
      return totolPoint.add(currentValue.point)
    }

    return totolPoint
  }, currency(0))

  return sum.value
}

const nearest_point_collapse = ref([])
const nearest_expire_point = computed<{ point: number; expire_date: string }>(
  () => {
    if (member_card.value && member_card.value.point_lot.length) {
      const nearestDate = findNearestDate(member_card.value.point_lot) // find the nearestDate
      const sumNearestPoint = sumNearestPointLot(
        member_card.value.point_lot,
        nearestDate,
      )
      return {
        point: sumNearestPoint,
        expire_date: nearestDate ? format(nearestDate, 'dd MMM yyyy') : t('membercard.no_expire'),
      }
    }

    return { point: 0, expire_date: '' }
  },
)
const pointLotFormatted = computed<PointLot[]>(() => {
  if (member_card.value && member_card.value.point_lot.length) {
    return member_card.value.point_lot.sort(
      (a, b) =>
        new Date(a.expire_date).getTime() - new Date(b.expire_date).getTime(),
    )
  }

  return []
})

onMounted(async () => {
  const params = getParamsFromLiffUrl(window.location.href)
  const clinic_uuid = params.get('c') || ''
  const member_card_uuid = params.get('u') || ''

  const repository = useMemberCardRepository(clinic_uuid)
  member_card.value = await repository.fetchMemberCardDetail(member_card_uuid)
})
</script>

<template>
  <div v-if="!member_card">
    <van-popup
      style="text-align: center; padding: 64px; border-radius: 20px"
      :show="true"
    >
      <van-loading color="green" text-color="black" size="48px" vertical>
        Loading...
      </van-loading>
    </van-popup>
  </div>

  <div v-else>
    <van-divider> {{ $t('membercard.title') }} </van-divider>
    <van-cell-group inset>
      <van-cell :title="$t('membercard.code')" :value="member_card.code" />
      <van-cell
        :title="$t('membercard.title')"
        :value="member_card.member_card.name"
      />
      <van-cell :title="$t('membercard.status')" value-class="active">
        <van-tag v-if="member_card.active_flag" type="success">{{
          $t('enable')
        }}</van-tag>
        <van-tag v-if="!member_card.active_flag" type="danger">{{
          $t('disable')
        }}</van-tag>
      </van-cell>
      <van-cell
        :title="$t('membercard.point_remain')"
        :value="member_card.point"
      />
      <van-cell
        :title="$t('membercard.money_remain')"
        :value="member_card.cash"
      />
      <van-cell :title="$t('membercard.expire_date')" :value="expire_date" />
    </van-cell-group>

    <template v-if="member_card.point_lot.length">
      <van-divider>{{ $t('membercard.point_lot') }}</van-divider>
      <van-cell-group inset>
        <van-cell
          :title="nearest_expire_point.point"
          :label="$t('membercard.nearest_point_lot_expire')"
          style="color: red"
          :value="nearest_expire_point.expire_date"
        />
        <van-collapse v-model="nearest_point_collapse">
          <van-collapse-item :title="$t('membercard.point_lot_more')" name="1">
            <van-cell
              v-for="lot in pointLotFormatted"
              :title="expireDatePointLot(lot.expire_date)"
              :label="$t('membercard.point_expire_date')"
              :value="lot.point"
            />
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>
    </template>

    <van-divider>{{ $t('membercard.benefits') }}</van-divider>
    <van-cell-group inset>
      <van-cell :title="$t('membercard.point_rate')" :value="point_rate" />
      <van-cell :title="$t('membercard.use_point_rate')" :value="cash_rate" />
      <van-tabs>
        <van-tab style="padding: 2%" :title="$t('membercard.discount')">
          <van-cell
            :title="$t(`membercard.medicine`)"
            :value="member_card.member_card.percent_discount_medicine"
          />
          <van-cell
            :title="$t(`membercard.service`)"
            :value="member_card.member_card.percent_discount_service"
          />
          <van-cell
            :title="$t('membercard.course')"
            :value="member_card.member_card.percent_discount_course"
          />
        </van-tab>
        <van-tab style="padding: 2%" :title="$t('membercard.point_rate')">
          <van-cell
            :title="$t('membercard.medicine')"
            :value="member_card.member_card.point_multiplier_medicine"
          />
          <van-cell
            :title="$t('membercard.service')"
            :value="member_card.member_card.point_multiplier_service"
          />
          <van-cell
            :title="$t('membercard.course')"
            :value="member_card.member_card.point_multiplier_course"
          />
          <van-cell
            :title="$t('membercard.voucher')"
            :value="member_card.member_card.point_multiplier_voucher"
          />
        </van-tab>
      </van-tabs>
    </van-cell-group>
  </div>
</template>
