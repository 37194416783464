import { useAppStore } from '@/stores/AppStore'
import { ShareInstance } from '@/type/Promotion'
import axios from 'axios'

export function usePromotionRepository(clinic_uuid: string) {
  const appStore = useAppStore()

  async function fetchChainPromotion(promotion_uuid: string) {
    try {
      const response = await axios.get(
        `${appStore.url}/api/v1/crm/line-app/p/chain-promotions/?promotion_uuid=${promotion_uuid}&line_uuid=${appStore.userProfile.userId}`,
      )

      return response.data
    } catch (error) {
      throw error
    }
  }

  async function createShare(promotion_uuid: string): Promise<ShareInstance> {
    try {
      const response = await axios.post(
        `${appStore.url}/api/v1/crm/line-app/p/share-chain-promotions/`,
        {
          promotion_uuid: promotion_uuid,
          clinic_uuid: clinic_uuid,
          line_user_id: appStore.userProfile.userId,
        },
      )

      return response.data
    } catch (error) {
      throw error
    }
  }

  async function completeShare({
    sender_share_uuid,
    reciever_share_uuid,
    promotion_uuid,
  }: {
    sender_share_uuid: string
    reciever_share_uuid: string
    promotion_uuid: string
  }) {
    try {
      const payload: any = {
        line_user_id: appStore.userProfile.userId,
        promotion_uuid: promotion_uuid,
        clinic_uuid: clinic_uuid,
      }

      if (reciever_share_uuid) {
        payload.reciever_share_uuid = reciever_share_uuid
      }

      if (sender_share_uuid) {
        payload.sender_share_uuid = sender_share_uuid
      }

      const response = await axios.put(
        `${appStore.url}/api/v1/crm/line-app/p/share-chain-promotions/`,
        payload,
      )

      return response.data
    } catch (error: any) {
      throw error
    }
  }

  async function deleteShare(share_uuid: string, promotion_uuid: string) {
    try {
      await axios.delete(
        `${appStore.url}/api/v1/crm/line-app/p/share-chain-promotions/`,
        {
          data: {
            share_uuid: share_uuid,
            promotion_uuid: promotion_uuid,
            clinic_uuid: clinic_uuid,
          },
        },
      )
    } catch (error) {
      throw error
    }
  }

  async function claimVoucherReward(promotion_uuid: string) {
    try {
      const response = await axios.post(
        `${appStore.url}/api/v1/crm/line-app/p/generate-voucher/`,
        {
          clinic_uuid: clinic_uuid,
          promotion_uuid: promotion_uuid,
          line_uuid: appStore.userProfile.userId,
        },
      )

      return response.data
    } catch (error) {
      throw error
    }
  }

  return {
    fetchChainPromotion,
    createShare,
    completeShare,
    deleteShare,
    claimVoucherReward,
  }
}
