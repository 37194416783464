import { useAppStore } from '@/stores/AppStore'
import { PatientCard } from '@/type/MemberCard'
import axios from 'axios'

export function useMemberCardRepository(clinic_uuid: string) {
  const appStore = useAppStore()

  async function fetchMemberCardDetail(
    member_card_uuid: string,
  ): Promise<PatientCard> {
    try {
      const response = await axios.get(
        `${appStore.url}/api/v1/crm/line-app/p/member-card/${member_card_uuid}/`,
        {
          params: {
            c: clinic_uuid,
            l: appStore.userProfile.userId,
          },
        },
      )

      return response.data
    } catch (error) {
      throw error
    }
  }

  return {
    fetchMemberCardDetail,
  }
}
