import { useAppStore } from '@/stores/AppStore'
import axios from 'axios'

export function useVoucherRepository(clinic_uuid: string) {
  const appStore = useAppStore()

  async function fetchVoucherCondition(voucher_uuid: string): Promise<string> {
    try {
      const response = await axios.get(
        `${appStore.url}/api/v1/crm/line-app/p/voucher-condition/${voucher_uuid}/`,
        {
          params: {
            c: clinic_uuid,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log(error)
      return ''
    }
  }

  return {
    fetchVoucherCondition,
  }
}
