<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import liff from '@line/liff'

// components
import LoadingDialog from '@components/LoadingDialog.vue'

// repo
import { useClinicRepository } from '@/repositories/clinicRepo'
import { useAppStore } from '@/stores/AppStore'

// types
import { AxiosError } from 'axios'
import TextDialog from '@/components/TextDialog.vue'
import { t } from 'i18next'
import { Button } from 'vant'

const loading = ref(false)
const alertDialog = ref(false)
const alertText = ref('')

const openAlertDialog = (text: string) => {
  loading.value = false
  alertText.value = text
  alertDialog.value = true
}

const buyCourseRedirect = async () => {
  loading.value = true
  const appStore = useAppStore()
  const route = useRoute()
  const clinic_uuid = route.query.c as string
  const liffId = route.query.liffId as string
  const clinicType = route.query.clinicType as string

  const repository = useClinicRepository(clinic_uuid)

  // fetch clinic data
  try {
    const clinic = await repository.fetchClinicMiniInfo()
    localStorage.setItem('clinic', JSON.stringify(clinic))
    appStore.setClinic(clinic)

    if (!clinic.sell_course_on_line) {
      openAlertDialog(t('redirect.disable_buy_course'))
      return
    }
  } catch (error) {
    console.error('error no clinic found')
    throw new Error('no clinic found')
  }

  // fetch patient data
  try {
    // Is 'old patient' save patient data and redirect to buy_course
    const patient = await repository.fetchPatientMiniInfo()
    localStorage.setItem('patient', JSON.stringify(patient))
    appStore.setPatient(patient)
    return router.replace({ name: 'CourseList' })
  } catch (error: AxiosError | any) {
    // no patient data in clinic
    console.error(error)
    if (error.isAxiosError && error.response.status === 404) {
      // try to find in localStorage
      // const local_patient = appStore.getLocalPatient()
      // if (local_patient) {
      //   appStore.setPatient(local_patient)
      //   return router.replace({ name: 'CourseList' })
      // }

      // So 'new patient' must register to clinic before buy_course
      try {
        const { linkToken } = await repository.generateLineAuthLinkToken()
        router.replace({
          path: `/auth/account-link/`,
          query: {
            c: clinic_uuid,
            liffId: liffId,
            clinicType: clinicType,
            linkToken: linkToken,
          },
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}

const notFoundRedirect = () => {
  router.replace({ name: 'NotFound' })
}

const closeLiff = () => {
  liff.closeWindow()
}

// if you want to passing query_params from redirect to destination
// passing { name: 'destination', qeury: route.query }
onMounted(() => {
  const route = useRoute()
  const redirect_path = route.params.re as string

  // redirect route
  switch (redirect_path) {
    case 'buy_course':
      return buyCourseRedirect()
    default:
      return notFoundRedirect()
  }
})
</script>

<template>
  <LoadingDialog :show="loading" />
  <TextDialog :show="alertDialog" :text="alertText">
    <template #button>
      <Button
        style="padding-left: 20px; padding-right: 20px"
        type="primary"
        @click="closeLiff"
        >ปิด</Button
      >
    </template>
  </TextDialog>
</template>
