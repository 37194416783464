import { useAppStore } from '@/stores/AppStore'
import {
  ClinicBranch,
  ClinicMini,
  PatientMini,
  PaymentLinkVerify,
  SexList,
  TitleList,
} from '@/type/Clinic'
import axios from 'axios'

export function useClinicRepository(clinic_uuid: string) {
  const appStore = useAppStore()

  async function fetchConsentFormAgreement(): Promise<{
    sms_agreement_text: string
  }> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/consent/?c=${clinic_uuid}`,
    )

    return response.data
  }

  async function fetchTitleList(): Promise<TitleList[]> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/form/title-list/?c=${clinic_uuid}`,
    )

    return response.data
  }

  async function fetchSexList(): Promise<SexList[]> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/form/sex-list/?c=${clinic_uuid}`,
    )

    return response.data
  }

  async function fetchPatientMiniInfo(): Promise<PatientMini> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/line-patient/?c=${clinic_uuid}&l=${appStore.userProfile.userId}`,
    )

    return response.data
  }

  async function fetchClinicMiniInfo(): Promise<ClinicMini> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/line-clinic/?c=${clinic_uuid}`,
    )

    return response.data
  }

  async function fetchClinicBranch(): Promise<ClinicBranch[]> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/branch/?c=${clinic_uuid}`,
    )

    return response.data
  }

  async function generateLineAuthLinkToken(): Promise<{ linkToken: string }> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/account-link/token/?c=${clinic_uuid}&l=${appStore.userProfile.userId}`,
    )

    return response.data
  }

  async function verifyPatientMobileDuplicate(
    patient: Partial<PatientMini>,
  ): Promise<{ verify_status: boolean }> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/verify/patient/`,
      {
        params: { ...patient, clinic_uuid: clinic_uuid },
      },
    )

    return response.data
  }

  async function verifyPatientPayment(): Promise<PaymentLinkVerify> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/verify/patient/payment/?c=${clinic_uuid}&l=${appStore.userProfile.userId}`,
    )

    return response.data
  }

  async function cancelPayment(): Promise<void> {
    await axios.put(
      `${appStore.url}/api/v1/crm/line-app/p/verify/patient/payment/?c=${clinic_uuid}&l=${appStore.userProfile.userId}`,
    )
  }

  return {
    fetchConsentFormAgreement,
    fetchTitleList,
    fetchSexList,
    fetchPatientMiniInfo,
    fetchClinicMiniInfo,
    fetchClinicBranch,
    generateLineAuthLinkToken,
    verifyPatientMobileDuplicate,
    verifyPatientPayment,
    cancelPayment,
  }
}
