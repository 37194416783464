import currency from 'currency.js'

export const $currency = (number: number | currency) => {
  return currency(number, { precision: 5 })
}

export const useCurrencyDisplay = (num: number | currency) => {
  return currency(num, { precision: 2 }).format({
    separator: ',',
    symbol: '฿',
  })
}
