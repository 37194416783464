<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'
import { isAxiosError } from 'axios'
import liff from '@line/liff'

// global
import { getParamsFromLiffUrl } from '@/utils'

// repositories
import {
  Promotion,
  promotionTemplate,
} from '../../../../packages/line-flex-msg-template/src/'
import { usePromotionRepository } from '@/repositories/promotionRepo'

let clinic_uuid: Ref<string | null> = ref(null)
let clinicType: Ref<string | null> = ref(null)
let promotion_uuid: Ref<string | null> = ref(null)
let share_uuid: Ref<string | null> = ref(null)

const params = getParamsFromLiffUrl(window.location.search)
clinic_uuid.value = params.get('c')
clinicType.value = params.get('clinicType')
promotion_uuid.value = params.get('u')
share_uuid.value = params.get('share_uuid')

const promotion: Ref<Promotion | null> = ref(null)
const pageLoading: Ref<boolean> = ref(false)
const buttonLoading: Ref<boolean> = ref(false)

if (!clinic_uuid.value) {
  promotion.value = null
}

const repository = usePromotionRepository(clinic_uuid.value || '')

// method
const onShare = async () => {
  buttonLoading.value = true
  try {
    if (!promotion_uuid.value) {
      return alert('Error')
    }

    // create share empty instance
    const share_instance = await repository.createShare(
      promotion_uuid.value, // promotion surely not be null
    )

    // create promotion share card
    // and new button share link
    const liff_url = `https://liff.line.me/${liff.id}/chain_promotion/?clinicType=${clinicType.value}&c=${clinic_uuid.value}&liffId=${liff.id}&u=${promotion_uuid.value}&share_uuid=${share_instance.share_uuid}`
    const share_message = {
      type: 'flex',
      altText: 'share promotion',
      contents: promotionTemplate.getPromotionCard({
        ...promotion.value!,
        button_url: liff_url,
      }),
    }

    // share line liff
    const isShared = await liff.shareTargetPicker([share_message] as any, {
      isMultiple: true,
    })

    // if user cancel share => delete share empty instance just created
    if (!isShared && promotion.value?.shared_data.shared_couter === 0) {
      // cancel share
      // repository.deleteShare(share_instance.share_uuid, promotion_uuid.value)
      return
    }

    // if complete share process update share instance
    await repository.completeShare({
      promotion_uuid: promotion_uuid.value,
      reciever_share_uuid: share_instance.share_uuid,
      sender_share_uuid: share_uuid.value || '',
    })

    liff.closeWindow()
  } catch (error) {
    alert(error)
    console.error(error)
  } finally {
    buttonLoading.value = false
  }
}

const onClaim = async () => {
  buttonLoading.value = true
  try {
    const res = await repository.claimVoucherReward(promotion_uuid.value!) // promotion surely not be null
    promotion.value!.shared_data.voucher_code = res.voucher_code
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      console.error(error.response.data)
      if (error.response.data.voucher) {
        alert(
          'เกิดข้อผิดพลาด ทำให้ไม่พบ Voucher ที่เป็นของรางวัล กรุณาติดต่อคลินิกเพื่อแจ้งปัญหาการใช้งาน',
        )
      }
    }
  } finally {
    buttonLoading.value = false
  }
}

const fetchChainPromotion = async () => {
  if (!promotion_uuid.value) {
    promotion.value = null
    return
  }

  try {
    promotion.value = await repository.fetchChainPromotion(promotion_uuid.value)
  } catch (error) {
    console.error(error)
    if (isAxiosError(error)) {
      switch (error.response?.status) {
        case 404:
          pageLoading.value = false
          break
      }
    }
  }
}

onMounted(async () => {
  // all of this must not be null
  pageLoading.value = true
  if (!clinic_uuid.value || !clinicType.value || !promotion_uuid.value) {
    promotion.value = null
    return
  }

  try {
    await fetchChainPromotion()

    // if 'easy' mode: count share for the sender upon open!
    if (share_uuid.value && promotion.value?.shared_data.easy) {
      await repository.completeShare({
        promotion_uuid: promotion_uuid.value,
        reciever_share_uuid: '', // will not create reciever instance on 'easy' mode
        sender_share_uuid: share_uuid.value,
      })
    }
  } catch (error) {
    console.error(error)
  }
  pageLoading.value = false
})
</script>

<template>
  <div v-if="!promotion && pageLoading">
    <van-popup
      style="text-align: center; padding: 64px; border-radius: 20px"
      :show="true"
    >
      <van-loading color="green" text-color="black" size="48px" vertical>
        Loading...
      </van-loading>
    </van-popup>
  </div>

  <div v-else-if="!promotion">
    <van-popup
      style="text-align: center; padding: 64px; border-radius: 20px"
      :show="true"
    >
      <p>{{ $t('promotion.error.not_found') }}</p>
    </van-popup>
  </div>

  <div v-else>
    <van-row style="margin: 20px 0px" justify="center">
      <van-image
        v-if="promotion.line_style.style === '03'"
        fit="scale-down"
        width="100rem"
        :src="promotion.image_url"
      />
      <van-image
        v-else
        fit="scale-down"
        style="
          width: 80%;
          height: 100%;
          max-height: 350px;
          object-fit: contain;
          aspect-ratio: 2 / 3.2;
        "
        :src="promotion.image_url"
      />
    </van-row>

    <!-- Title -->
    <div class="content">
      <div v-html="promotion.chain_promotion_liff.liff_title"></div>
    </div>

    <!-- Note -->
    <div class="content">
      <div
        style="height: 100%; padding-bottom: 60px"
        v-html="promotion.chain_promotion_liff.liff_note"
      ></div>
    </div>

    <!-- WINNER FOOTER -->
    <div
      v-if="promotion.shared_data.voucher_code"
      style="background-color: #06c755"
      class="footer"
    >
      <div class="col1 item-center">
        <span> REDEEM CODE </span><br />
        <span> {{ promotion.shared_data.voucher_code }} </span>
      </div>
    </div>

    <!-- PLAYER FOOTER -->
    <div v-else style="background-color: #555555" class="footer grid">
      <div class="col1 item-center">
        <span>{{ $t('promotion.current_sharer') }}</span
        ><br />
        <span
          >{{ promotion.shared_data.shared_couter }} /
          {{ promotion.chain_promotion_liff.total_share_condition }}
          {{ $t('promotion.unit') }}</span
        >
      </div>

      <div class="item-center">
        <!-- CLAIM BUTTON -->
        <van-button
          :loading="buttonLoading"
          v-if="
            promotion.shared_data.shared_couter >=
            promotion.chain_promotion_liff.total_share_condition
          "
          type="success"
          @click="onClaim"
          >Claim Reward</van-button
        >

        <!-- SHARE BUTTON -->
        <van-button v-else type="success" @click="onShare"> Share </van-button>
      </div>
    </div>
  </div>
</template>

<style>
.col1 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.content {
  text-align: center;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto;
}

.footer {
  justify-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: #555555;
  color: white;
  text-align: center;
}

.item-center {
  align-self: center;
}
</style>
