<script setup lang="ts">
import { ref, onMounted } from 'vue'
import {
  CreateReviewDTO,
  FeedbackQuestion,
  FeedbackQuestionForm,
  OPD,
} from '../type/Feedback.ts'
import { format } from 'date-fns'
import { useFeedbackRepository } from '../repositories/feedbackRepo.ts'
import { getParamsFromLiffUrl } from '@/utils'
import { isAxiosError } from 'axios'
import liff from '@line/liff'
import { NotifyType } from 'vant'
import { t } from 'i18next'
import LoadingDialog from '@components/LoadingDialog.vue'

const params = getParamsFromLiffUrl(window.location.href)
const clinic_uuid = params.get('c') || ''
const opd_uuid = params.get('u') || ''
const repository = useFeedbackRepository(clinic_uuid)

const is_loading = ref(true)
const is_review = ref(false)
const opd_data = ref<OPD | null>(null)
const collapseActiveName = ref('')
const question_form = ref<FeedbackQuestionForm[]>([])
const score_text_display = (score: number, question: FeedbackQuestionForm) => {
  switch (score) {
    case 0:
      return ''
    case 1:
      return question.score_text.one_point_text
    case 2:
      return question.score_text.two_point_text
    case 3:
      return question.score_text.three_point_text
    case 4:
      return question.score_text.four_point_text
    case 5:
      return question.score_text.five_point_text
    default:
      return ''
  }
}

const confirm_dialog = ref(false)
const openConfirmDialog = () => {
  if (!validateScore()) return false

  confirm_dialog.value = true
}
const closeConfirmDialog = () => {
  confirm_dialog.value = false
}

const snackbar_error = ref(false)
const snackbar_text = ref('')
const snackbar_type = ref<NotifyType>('primary')
const openSnackbarError = (text: string, type: NotifyType) => {
  snackbar_text.value = text
  snackbar_type.value = type
  snackbar_error.value = true
  setTimeout(() => {
    snackbar_error.value = false
  }, 3000)
}

const success_dialog = ref(false)
const onCloseLiff = () => {
  liff.closeWindow()
}

const validateScore = () => {
  let validate = true
  question_form.value.forEach(question => {
    if (question.is_score && !question.score) {
      question.score_error = 'Required'
      validate = false
    }
  })

  return validate
}
const onSubmit = async () => {
  try {
    const payload: CreateReviewDTO[] = question_form.value.map(question => {
      return {
        title: question.title,
        score: question.score,
        score_text: score_text_display(question.score, question),
        comment: question.comment,
        category: question.category,
        is_comment: question.is_comment,
        is_score: question.is_score,
      }
    })

    await repository.createOPDReview(opd_uuid, payload)
    success_dialog.value = true
  } catch (error) {
    console.error(error)
    if (isAxiosError(error)) {
      if (error.response?.status === 400) {
        openSnackbarError(t('feedback.error.do_not_double_review'), 'danger')
      }
    } else {
      console.error('Fetch error', error)
    }
  }
}

onMounted(async () => {
  is_loading.value = true
  try {
    const { data } = await repository.fetchOPDReview(opd_uuid) // ดึงว่ารีวิวไปยัง?
    is_review.value = data.is_review // toggle รีวิวแล้วหรือยังไม่ได้รีวิว

    // ถ้ายังไม่ได้ และมีข้อมูลครบถ้วน
    if (!data.is_review && data.opd && data.questions) {
      opd_data.value = data.opd
      question_form.value = data.questions.map((question: FeedbackQuestion) => {
        return {
          ...question,
          score: 0,
          comment: '',
          score_error: '',
        }
      })
    }
  } catch (error) {
    console.error(error)
  }
  is_loading.value = false
})
</script>

<template>
  <LoadingDialog v-if="is_loading" :show="true">
    <template #default>
      <p style="margin-top: 10px">{{ $t('feedback.loading') }}</p>
    </template>
  </LoadingDialog>

  <template v-if="!is_loading && is_review">
    <p style="text-align: center">
      {{ $t('feedback.error.opd_already_review') }}
    </p>
  </template>

  <template
    v-else-if="
      !is_loading && !is_review && (!opd_data || question_form.length === 0)
    "
  >
    <p style="text-align: center">
      {{ $t('feedback.error.opd_error') }}
    </p>
  </template>

  <template v-else-if="!is_loading && !is_review && opd_data">
    <van-cell-group v-if="opd_data.uuid" inset style="margin-top: 10px">
      <van-cell
        :title="$t('feedback.branch')"
        :value="opd_data.branch_name"
        :label="`${opd_data.clinic_name}`"
      />
      <van-cell :title="$t('feedback.opd_number')" :value="opd_data.code" />
      <van-collapse v-model="collapseActiveName" accordion>
        <van-collapse-item :title="$t('feedback.opd_item.label')" name="1">
          <van-cell
            v-for="item in opd_data.opditems"
            :value="`x${parseFloat(item.amount)}`"
          >
            <template #title>
              <p class="custom-title">{{ item.action }}</p>
              <van-tag v-if="item.item_type === 'Course'" type="success">{{
                $t('feedback.opd_item.course')
              }}</van-tag>
              <van-tag
                v-else-if="item.item_type === 'Medicine'"
                type="primary"
                >{{ $t('feedback.opd_item.medicine') }}</van-tag
              >
              <van-tag v-else-if="item.item_type === 'Service'" type="danger">{{
                $t('feedback.opd_item.service')
              }}</van-tag>
              <van-tag v-else type="default">{{ item.item_type }}</van-tag>
            </template>
          </van-cell>
        </van-collapse-item>
      </van-collapse>
      <van-cell :title="$t('doctor')" :value="opd_data.doctor_name" />
      <van-cell
        :title="$t('feedback.assistance')"
        :value="opd_data.assistance_name"
      />
      <van-cell
        v-if="opd_data.create_date"
        :title="$t('feedback.create_date')"
        :value="format(new Date(opd_data.create_date), 'dd MMM yyyy HH:mm')"
      />
    </van-cell-group>

    <van-divider>{{ $t('feedback.review') }}</van-divider>

    <van-form @submit="openConfirmDialog">
      <div class="question-box">
        <div
          v-for="(question, index) in question_form"
          :key="index"
          inset
          style="margin-top: 10px"
        >
          <p style="font-size: small; margin-bottom: 0px">
            {{ question.title }}
          </p>
          <div style="display: flex; align-items: baseline">
            <van-rate
              v-if="question.is_score"
              style="margin-top: 10px; margin-right: 10px"
              color="#ffd21e"
              v-model="question.score"
            />
            <span
              v-if="!question.score && question.score_error"
              style="font-size: smaller; color: red"
              >{{ question.score_error }}</span
            >
            <span v-else style="font-size: small; color: #555555">{{
              score_text_display(question.score, question)
            }}</span>
          </div>
          <van-field
            v-if="question.is_comment"
            class="text-field"
            v-model="question.comment"
            type="textarea"
            row="2"
            autosize
            maxlength="100"
            show-word-limit
            :rules="[
              { required: question.is_comment_required, message: 'Required' },
            ]"
          />
          <van-divider></van-divider>
        </div>

        <div style="margin-top: 10px">
          <van-button round block type="success" native-type="submit">
            {{ $t('submit') }}
          </van-button>
        </div>
      </div>
    </van-form>

    <van-dialog
      v-model:show="confirm_dialog"
      :title="$t('feedback.dialog.review_confirm_title')"
      :message="$t('feedback.dialog.review_confirm_detail')"
      show-cancel-button
      @confirm="onSubmit"
      @cancel="closeConfirmDialog"
    >
    </van-dialog>

    <van-dialog
      v-model:show="success_dialog"
      :title="$t('feedback.dialog.review_success_title')"
      :message="$t('feedback.dialog.review_success_detail')"
      @confirm="onCloseLiff"
    >
    </van-dialog>

    <van-notify v-model:show="snackbar_error" :type="snackbar_type">
      <span>{{ snackbar_text }}</span>
    </van-notify>
  </template>
</template>

<style scoped>
.question-box {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.text-field {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #00000010;
  border-radius: 5px;
}

.custom-title {
  margin: 0px;
  vertical-align: middle;
}
</style>
