import { useAppStore } from '@/stores/AppStore'
import { BFAlbum } from '@/type/BFAlbum'
import axios from 'axios'

export function useBFRepository(clinic_uuid: string) {
  const appStore = useAppStore()

  async function fetchBFAlbum(): Promise<BFAlbum[]> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/bf-showcase/?c=${clinic_uuid}`,
    )
    return response.data
  }

  async function fetchTags() {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/bf-showcase/tag/?c=${clinic_uuid}`,
    )
    return response.data
  }

  return {
    fetchBFAlbum,
    fetchTags,
  }
}
